import { Box, Stack, TextField } from '@mui/material';
import { ChangeEventHandler, ReactNode } from 'react';

export function UncontrolledMobileNumericInput({
    label,
    value,
    onChange,
}: {
    label: ReactNode;
    value?: unknown;
    onChange: ChangeEventHandler<HTMLInputElement>;
}) {
    return (
        <Stack
            direction={'row'}
            sx={theme => ({
                backgroundColor: theme.palette.blue.light,
                color: theme.palette.primary.main,
                padding: theme.spacing(2),
                borderRadius: '12px',
                diplay: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: 600,
            })}
        >
            <Box>{label}</Box>
            <TextField
                type="tel"
                inputMode="numeric"
                variant="filled"
                onChange={onChange}
                value={value}
                slotProps={{
                    htmlInput: {
                        min: 0,
                        step: 1,
                    },
                }}
                sx={theme => ({
                    width: '80px',
                    '& .MuiInputBase-input': {
                        borderRadius: '3px',
                        backgroundColor: theme.palette.blue.medium,
                        color: theme.palette.primary.main,
                        fontSize: '24px',
                        padding: '8px',
                    },
                })}
            />
        </Stack>
    );
}
