import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import {
    MiniTable,
    MiniTableElement,
} from '../../ui/labeled-elements/MiniTable.tsx';
import * as Text from '../../common/i18n/Text.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { operatorLoginsListTexts } from '../i18n/OperatorLoginsTexts.ts';
import {
    Body,
    ColumnContent,
    Container,
    LeftColumn,
    RightColumn,
    TabItem,
    Tabs,
} from '../../ui/layout/TabContent.tsx';
import { OperatorLoginsDetailSlideIn } from './OperatorLoginsDetailSlideIn.tsx';
import { OperatorAdminOrSuperuserDetailSlideIn } from './OperatorAdminOrSuperuserDetailSlideIn.tsx';
import { OperatorLoginPermitTypeSlideIn } from './OperatorLoginPermitTypeSlideIn.tsx';
import { OperatorChangePasswordSlideIn } from './OperatorChangePasswordSlideIn.tsx';
import {
    IconButton40px,
    IconButton40pxType,
} from '../../ui/buttons/IconButton.tsx';
import { openCreateSlideIn } from '../actions/OperatorLoginActions.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { CurrentOperatorLogin } from '../../common/state/CurrentOperatorLoginState.ts';
import { OperatorLoginZoneRestraintSlideIn } from './OperatorLoginZoneRestraintSlideIn.tsx';
import { css } from '@emotion/css';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { getNavigationText } from '../../layout/components/BreadCrumb.tsx';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorLoginProductTypeSlideIn } from './OperatorLoginProductTypeSlideIn.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import { OperatorLoginRecodeConditionSlideIn } from './OperatorLoginRecodeConditionSlideIn.tsx';
import { ContractTemplateOperatorState } from 'dg-web-shared/model/ContractTemplateOperatorState.tsx';

export interface OperatorLoginsListTexts {
    activeUsers: Text.Translation;
    administrators: Text.Translation;
    superusers: Text.Translation;
    all: Text.Translation;
    softAnonymizeCheckinPublicPermitAfterDaysLabel: Text.Translation;
    inactiveUsers: Text.Translation;
    none: Text.Translation;
    onlyEnforcement: Text.Translation;
    password1: Text.Translation;
    password2: Text.Translation;
    permActive: Text.Translation;
    permActivityStatement: Text.Translation;
    permEnforcementLog: Text.Translation;
    permParkingMeterAlerts: Text.Translation;
    permParkingMeterWrite: Text.Translation;
    permParkingpayModule: Text.Translation;
    permPermitsRead: Text.Translation;
    permPermitsWrite: Text.Translation;
    permRefundPermits: Text.Translation;
    permSyncApp: Text.Translation;
    permTaxomexModule: Text.Translation;
    permTwintQrCodePairing: Text.Translation;
    permUsbStickWrite: Text.Translation;
    permWhitelistCreate: Text.Translation;
    permWhitelistDelete: Text.Translation;
    permissions: Text.Translation;
    permitTypes: Text.Translation;
    saveEdit: Text.Translation;
    saveNew: Text.Translation;
    savePassword: Text.Translation;
    some: Text.Translation;
    userPermissionEdit: Text.Translation;
    zones: Text.Translation;
}

export enum LoginsGroup {
    SUPER_USER = 'SUPER_USER',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

interface CommonOperatorLoginsState {
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    settings: SettingsState.State;
    currentLogin: CurrentOperatorLoginState.State;
    layout: OperatorLoginsState.Layout.State;
}

export class OperatorLoginsList extends Flux.Container<CommonOperatorLoginsState> {
    stateSelector(): CommonOperatorLoginsState {
        return {
            operatorLoginsList: CommonOperatorLoginsState.List.get(
                this.props.allState,
            ),
            settings: new SettingsState.StateSlice(this.props.allState).state,
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
            layout: OperatorLoginsState.Layout.get(this.props.allState),
        };
    }

    isAdmin(): boolean {
        return (
            !!this.state.currentLogin.data &&
            this.state.currentLogin.data.isAdmin
        );
    }

    renderUsers(
        logins: CommonOperatorLoginsState.OperatorLogin[],
    ): JSX.Element[] {
        return logins.map(l => (
            <MiniTableElement
                onLineItemClick={() =>
                    this.props.allState.update(store =>
                        OperatorLoginsState.Layout.stateWrite(store, {
                            selectedOperatorLogin: l.operatorLoginId,
                        }),
                    )
                }
                focused={
                    l.operatorLoginId ===
                    this.state.layout.selectedOperatorLogin
                }
                key={l.operatorLoginId}
            >
                {CommonOperatorLoginsState.getLoginTitle(l)}
            </MiniTableElement>
        ));
    }

    renderGroup(group: LoginsGroup, label: string): JSX.Element | null {
        const currentLogin = this.state.currentLogin.data;
        const logins = CommonOperatorLoginsState.userLogins(
            this.state.operatorLoginsList.data,
            group,
        ).filter(
            l =>
                currentLogin &&
                (currentLogin.isAdmin ||
                    currentLogin.permissions.userPermissionEdit ||
                    l.operatorLoginId === currentLogin.loginId),
        );
        if (logins.length === 0) {
            return null;
        }
        return (
            <MiniTable actionButtons={false} label={label}>
                <br />
                {this.renderUsers(logins)}
            </MiniTable>
        );
    }

    renderAdmin(): JSX.Element | null {
        if (!this.isAdmin()) {
            return null;
        }
        const texts = operatorLoginsListTexts[this.state.settings.language];
        const admin = CommonOperatorLoginsState.administatorLogin(
            this.state.operatorLoginsList.data,
        );
        return (
            <MiniTable actionButtons={false} label={texts.administrators()}>
                <MiniTableElement
                    onLineItemClick={() =>
                        this.props.allState.update(store =>
                            OperatorLoginsState.Layout.stateWrite(store, {
                                selectedOperatorLogin: admin.operatorLoginId,
                            }),
                        )
                    }
                    focused={
                        admin.operatorLoginId ===
                        this.state.layout.selectedOperatorLogin
                    }
                    key={'admin'}
                >
                    <br />

                    {CommonOperatorLoginsState.getLoginTitle(admin)}
                </MiniTableElement>
                <br />
            </MiniTable>
        );
    }

    renderSlideIns() {
        const currentLogin = this.state.currentLogin.data;
        if (!currentLogin) {
            return null;
        }
        return (
            <SlideInsWithLoadedProductTemplates currentLogin={currentLogin} />
        );
    }

    render() {
        const currentLogin = this.state.currentLogin.data;
        if (this.state.operatorLoginsList.data.length === 0 || !currentLogin) {
            return <noscript />;
        }

        const texts = operatorLoginsListTexts[this.state.settings.language];
        return (
            <Container>
                <Tabs>
                    <TabItem
                        active={true}
                        description={getNavigationText(
                            OperatorAppRoutes.Logins,
                        )}
                        onClick={null}
                    />
                </Tabs>
                <Body
                    disabled={!!this.state.layout.selectedOperatorLogin}
                    slideIns={this.renderSlideIns()}
                >
                    <ColumnContent>
                        <LeftColumn>
                            {this.renderAdmin()}
                            {this.renderGroup(
                                LoginsGroup.SUPER_USER,
                                texts.superusers(),
                            )}
                        </LeftColumn>
                        <RightColumn>
                            {this.renderGroup(
                                LoginsGroup.ACTIVE,
                                texts.activeUsers(),
                            )}
                            <br />
                            {this.renderGroup(
                                LoginsGroup.INACTIVE,
                                texts.inactiveUsers(),
                            )}
                        </RightColumn>
                    </ColumnContent>
                    {(currentLogin.isAdmin ||
                        currentLogin.permissions.userPermissionEdit) && (
                        <div
                            className={css({
                                position: 'absolute',
                                right: '36px',
                                top: '12px',
                            })}
                        >
                            <IconButton40px
                                type={IconButton40pxType.add}
                                onClick={() => this.update(openCreateSlideIn)}
                            />
                        </div>
                    )}
                </Body>
            </Container>
        );
    }
}

export type ProductName = {
    [key in 'de' | 'fr' | 'it' | 'en']: string;
};

export type ProductType = {
    contractTemplateId: number;
    productTemplateVersionId: string;
    productName: ProductName;
    operatorState: ContractTemplateOperatorState;
};

export type RecodeCondition = {
    recodeConditionId: string;
    description: Message;
};

export function SlideInsWithLoadedProductTemplates({
    currentLogin,
}: {
    currentLogin: CurrentOperatorLogin;
}) {
    const [products] = useServerFetch<ProductType[], object>(
        () => ({
            url: `/ui-api/operator-account/product-templates`,
        }),
        {},
    );
    const [recodeConditions] = useServerFetch<RecodeCondition[], object>(
        () => ({
            url: `/ui-api/operator-account/cloud-connector-recode-conditions`,
        }),
        {},
    );
    const { store } = useStore(() => ({}));

    return (
        <>
            <OperatorLoginsDetailSlideIn
                currentLogin={currentLogin}
                products={products.data ?? []}
                recodeConditions={recodeConditions.data ?? []}
            />
            <OperatorAdminOrSuperuserDetailSlideIn
                currentLogin={currentLogin}
                allState={store}
            />
            <OperatorLoginPermitTypeSlideIn allState={store} />
            <OperatorLoginProductTypeSlideIn products={products.data ?? []} />
            <OperatorLoginZoneRestraintSlideIn />
            <OperatorLoginRecodeConditionSlideIn
                recodeConditions={recodeConditions.data ?? []}
            />
            <OperatorChangePasswordSlideIn allState={store} />
        </>
    );
}
