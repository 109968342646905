export interface ZoneEnforcedConfig extends ZoneEnforcedConfigShort {
    nextConfig?: ZoneEnforcedConfigShort;
    previousConfig?: ZoneEnforcedConfigShort;
    editable: boolean;
    releasingAdminName?: string | null;
    validTo: string;
    parkingpayTariffId: number | null;
    parkingpayMinChfStep: number | null;
    customerCommission: boolean;
    permitConfigurations: PermitConfiguration[];
    tomEcoTariffId: number | null;
    euroExchangeRate: number;
    additionalPaymentProhibited: boolean;
    nightParking: NightParkingConfiguration | null;
}

export interface ZoneEnforcedConfigShort {
    zoneId: number;
    zoneEnforcedConfigId: number;
    activeConfiguration: boolean;
    specificationStatus: ZoneConfigSpecificationState;
    validFrom: string;
}

export interface NightParkingConfiguration {
    timeFrom: string;
    timeTo: string;
    allowedChecksWithoutParkingRight: number;
    period: NightParkingPeriod;
}

export enum NightParkingPeriod {
    CALENDAR_MONTH = 'CALENDAR_MONTH',
    CALENDAR_WEEK = 'CALENDAR_WEEK',
}

export enum ZoneConfigSpecificationState {
    planning = 'planning',
    released = 'released',
    invalidated = 'invalidated',
}

export interface PermitConfiguration {
    tariffId?: number;
    permitTypeId: number;
    description: string;
    minChfStep: number;
}

export enum HopperConfigType {
    CASHLESS = 'CASHLESS',
    NRI_50_200 = 'NRI_50_200',
    NRI_50_100 = 'NRI_50_100',
    NRI_100_200 = 'NRI_100_200',
}
