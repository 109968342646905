import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { RecodeCondition } from './OperatorLoginsList.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { StringMultiSelectionSlideIn } from '../../ui/slidein/MultiSelectionSlideIn.tsx';
import { Localized } from '../../common/components/Localized.tsx';

interface OperatorLoginRecodeConditionSlideInState {
    layout: OperatorLoginsState.Layout.State;
    operatorLoginsList: CommonOperatorLoginsState.List.State;
    edit: OperatorLoginsState.Edit.State;
    settings: SettingsState.State;
}

export function OperatorLoginRecodeConditionSlideIn({
    recodeConditions,
}: {
    recodeConditions: RecodeCondition[];
}) {
    const { storeState, update } =
        useStore<OperatorLoginRecodeConditionSlideInState>(store => {
            return {
                layout: OperatorLoginsState.Layout.get(store),
                operatorLoginsList: CommonOperatorLoginsState.List.get(store),
                edit: OperatorLoginsState.Edit.get(store),
                settings: new SettingsState.StateSlice(store).state,
            };
        });

    const selectedId = storeState.layout.selectedOperatorLogin;
    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );

    if (!login && !storeState.layout.createEnabled) {
        return null;
    } else {
        const loginAllowedRecodeConditions = login
            ? login.allowedRecodeConditions
            : [];
        const allowedRecodeConditions = storeState.edit.allowedRecodeConditions
            ? storeState.edit.allowedRecodeConditions
            : loginAllowedRecodeConditions;

        return (
            <StringMultiSelectionSlideIn
                open={storeState.layout.recodeConditionSelectionOpen}
                heading={
                    <Localized
                        de="Rabattbedingungen"
                        fr="Conditions de rabais"
                        it="Condizioni ribasso"
                        en="Discount conditions"
                    />
                }
                selection={allowedRecodeConditions}
                options={recodeConditions
                    .sort((a, b) =>
                        localizedRecodeConditionDescription(
                            a,
                            storeState.settings.language,
                        ).localeCompare(
                            localizedRecodeConditionDescription(
                                b,
                                storeState.settings.language,
                            ),
                        ),
                    )
                    .map(condition => ({
                        id: condition.recodeConditionId,
                        displayText: <Localized {...condition.description} />,
                    }))}
                onLeftActionClick={() =>
                    update(store =>
                        OperatorLoginsState.Layout.stateWrite(store, {
                            recodeConditionSelectionOpen: false,
                        }),
                    )
                }
                setNewSelection={(v: string[]) =>
                    update(store =>
                        OperatorLoginsState.Edit.stateWrite(store, {
                            allowedRecodeConditions: v,
                        }),
                    )
                }
                infoText={
                    recodeConditions.length !=
                    allowedRecodeConditions.length ? (
                        <Localized
                            de="Neue Rabattbedingungen werden nicht automatisch zugelassen."
                            fr="Les nouvelles conditions de rabais ne sont pas automatiquement autorisées."
                            it="Le nuove condizioni di ribasso non sono autorizzate automaticamente."
                            en="New discount conditions are not automatically allowed."
                        />
                    ) : null
                }
            />
        );
    }
}

function localizedRecodeConditionDescription(
    recodeCondition: RecodeCondition,
    language: string,
): string {
    switch (language) {
        case 'fr':
            return recodeCondition.description.fr;
        case 'it':
            return recodeCondition.description.it;
        case 'en':
            return recodeCondition.description.en;
        default:
            return recodeCondition.description.de;
    }
}
