import { Box } from '@mui/material';
import { ReactNode } from 'react';

export function MobileSectionTitle({ children }: { children: ReactNode }) {
    return (
        <Box
            sx={theme => ({
                diplay: 'flex',
                textAlign: 'center',
                padding: '0px 24px 24px 24px',
                color: theme.palette.primary.main,
                fontSize: '28px',
                fontWeight: 700,
            })}
        >
            {children}
        </Box>
    );
}
