import {
    CloudConnectorPerifheryEventType,
    CoinType,
    HopperCoinCount,
    PayStationDetailInfo,
    PeripheryConfig,
    PeripheryInfoType,
} from './CloudConnectorPeripheriesOverview.tsx';
import {
    generatePath,
    Navigate,
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import {
    RequestStatus,
    useServerFetch,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { isMobileRoute } from '../layout/components/BreadCrumb.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import Alert from '@mui/material/Alert';
import { Box, Button, InputLabel, Stack } from '@mui/material';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { ParkingPortalButton } from '../mobile/layout/ParkingPortalButton.tsx';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import {
    Edit,
    KeyboardCapslock,
    KeyboardControlKey,
} from '@mui/icons-material';
import { HopperConfigType } from 'dg-web-shared/model/ZoneConfiguration.ts';
import { numberToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';

enum CloudConnectorCommandType {
    BARRIER_UP_ONCE = 'BARRIER_UP_ONCE',
    BARRIER_PERMANENTLY_OPEN = 'BARRIER_PERMANENTLY_OPEN',
}

interface CloudConnectorPeripheryRequest {
    operatorId: number;
    cloudConnectorId: string;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    commandType: CloudConnectorCommandType;
    secondaryGate: boolean | null;
    permanentlyOpen: boolean | null;
}

function isCloudConnectorPeripheryType(
    value?: string,
): value is CloudConnectorPeripheryType {
    return Object.values(CloudConnectorPeripheryType).includes(
        value as CloudConnectorPeripheryType,
    );
}

export function getCloudConnectorPeripheryType(
    value?: string,
): CloudConnectorPeripheryType | null {
    if (isCloudConnectorPeripheryType(value)) {
        return value;
    }
    return null;
}

export function usePeripheryDetailsOutlet() {
    return useOutletContext<PeripheryDetailsOutletContext>();
}

function PeripheryDetailsOutlet({
    context,
}: {
    context: PeripheryDetailsOutletContext;
}) {
    return <Outlet context={context} />;
}

interface PeripheryDetailsOutletContext {
    peripheryName: string;
    refetchCloudConnectorPeriphery: () => void;
}

export function CloudConnectorPeripheriesDetails() {
    const urlParams = useParams<{
        cloudConnectorId: string;
        peripheryType: string;
        onPremiseId: string;
    }>();
    const cloudConnectorId = urlParams.cloudConnectorId;
    const peripheryType = getCloudConnectorPeripheryType(
        urlParams.peripheryType,
    );
    const onPremiseId = urlParams.onPremiseId
        ? Number(urlParams.onPremiseId)
        : null;

    const [cloudConnectorPeripheryState, refetchCloudConnectorPeriphery] =
        useServerFetch<
            PeripheryConfig,
            {
                cloudConnectorId: string | null;
                peripheryType: CloudConnectorPeripheryType | null;
                onPremiseId: number | null;
            }
        >(
            context => ({
                url: `/ui-api/operator-account/cloud-connector/periphery/${context.cloudConnectorId}/${context.peripheryType}/${context.onPremiseId}`,
            }),
            {
                cloudConnectorId: cloudConnectorId ? cloudConnectorId : null,
                peripheryType: peripheryType ? peripheryType : null,
                onPremiseId: onPremiseId ? onPremiseId : null,
            },
        );
    const isMobile = isMobileRoute();
    return (
        <OperatorAsyncLoadedSection
            requestState={cloudConnectorPeripheryState}
            render={peripheryConfig => (
                <OperatorRoutedModal
                    variant={
                        isMobile ? ModalVariant.MOBILE : ModalVariant.SMALL
                    }
                    backUrl={'..'}
                    render={() => (
                        <ParkingPortalLayoutWithHeader
                            title={peripheryConfig.name}
                            backTo={'..'}
                        >
                            <PeripheryDetail
                                peripheryConfig={peripheryConfig}
                            />
                            <PeripheryDetailsOutlet
                                context={{
                                    peripheryName: peripheryConfig.name,
                                    refetchCloudConnectorPeriphery:
                                        refetchCloudConnectorPeriphery,
                                }}
                            />
                        </ParkingPortalLayoutWithHeader>
                    )}
                />
            )}
        />
    );
}

function PeripheryDetail({
    peripheryConfig,
}: {
    peripheryConfig: PeripheryConfig;
}) {
    const [cloudConnectorRequestState, cloudConnectorRequest] = useServerWrite<
        CloudConnectorPeripheryRequest,
        null
    >(({ operatorId, cloudConnectorId }) => ({
        url: `/ui-api/operator-account/${operatorId}/cloud-connector/${cloudConnectorId}/periphery-command`,
    }));

    switch (cloudConnectorRequestState.status) {
        case RequestStatus.NEVER_EXECUTED:
        case RequestStatus.ERROR:
            return (
                <Stack
                    direction={'column'}
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        padding: theme => theme.spacing(3),
                    }}
                >
                    <RequestError
                        open={
                            cloudConnectorRequestState.status ===
                            RequestStatus.ERROR
                        }
                    />
                    <PeripheryInfo peripheryConfig={peripheryConfig} />
                    <Stack spacing={2}>
                        <CommandList
                            peripheryConfig={peripheryConfig}
                            cloudConnectorRequest={cloudConnectorRequest}
                        />
                    </Stack>
                </Stack>
            );
        case RequestStatus.PENDING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case RequestStatus.SUCCESS:
            return <Navigate to={'..'} />;
    }
}

function PeripheryInfo({
    peripheryConfig,
}: {
    peripheryConfig: PeripheryConfig;
}) {
    if (peripheryConfig.info == null) {
        return <Box sx={{ display: 'flex', flexGrow: 1 }} />;
    }
    switch (peripheryConfig.info.peripheryInfoType) {
        case PeripheryInfoType.TICKET_REMAINING:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        textTransform: 'uppercase',
                    }}
                >
                    <ReadOnlyTextField
                        label={
                            <Localized
                                de="Verbleibende Tickets"
                                fr="Billets restants"
                                it="Bigletti rimaneti"
                                en="Remaining tickets"
                            />
                        }
                        value={peripheryConfig.info.ticketRemaining}
                    />
                </Box>
            );
        case PeripheryInfoType.PAY_STATION_INFO: {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    <InputLabel
                        sx={{
                            lineHeight: '1em',
                            paddingTop: '1px',
                            textTransform: 'uppercase',
                        }}
                    >
                        <Localized
                            de="Münzstand"
                            fr="État de la monnaie"
                            it="Stato moneta"
                            en="Coin state"
                        />
                    </InputLabel>
                    <HopperCoinCountInfo
                        payStationDetailInfo={peripheryConfig.info}
                    />
                </Box>
            );
        }
    }
}

function HopperCoinCountInfo({
    payStationDetailInfo,
}: {
    payStationDetailInfo: PayStationDetailInfo;
}) {
    return (
        <Stack direction={'column'} spacing={1}>
            <Stack direction={'row'} spacing={1}>
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.TOP_LEFT}
                />
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.TOP_RIGHT}
                />
            </Stack>
            <Stack direction={'row'} spacing={1}>
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.BOTTOM_LEFT}
                />
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.BOTTOM_RIGHT}
                />
            </Stack>
        </Stack>
    );
}

function HopperCoinCountBox({
    payStationDetailInfo,
    hopperPosition,
}: {
    payStationDetailInfo: PayStationDetailInfo;
    hopperPosition: HopperPosition;
}) {
    const navigate = useNavigate();
    const coinCount = getCoinCountByPosition(
        payStationDetailInfo,
        hopperPosition,
    );
    return (
        <Box
            sx={theme => ({
                color: theme.palette.white.main,
                backgroundColor: theme.palette.primary.main,
                padding: theme.spacing(1.4),
                fontWeight: 700,
                flexGrow: 1,
                aspectRatio: 1.5,
                display: 'flex',
                flexDirection: 'column',
            })}
        >
            {coinCount && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            fontSize: '28px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {`${coinCount.numberOfCoins}x`}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontSize: '16px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            {numberToLocalPrice(
                                'de',
                                coinCount.chfDenomination,
                            )}
                        </Box>
                        <Button
                            sx={{
                                color: theme => theme.palette.white.main,
                                padding: 0,
                                minWidth: '24px !important',
                            }}
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        ':coinType/manual-correction',
                                        coinCount,
                                    ),
                                )
                            }
                        >
                            <Edit
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                }}
                            />
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}

enum HopperPosition {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

function getCoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (info.hopperConfigType) {
        case HopperConfigType.CASHLESS:
            return null;
        case HopperConfigType.NRI_50_200:
            return getNRI_50_200CoinCountByPosition(info, hopperPosition);
        case HopperConfigType.NRI_50_100:
            return getNRI_50_100CoinCountByPosition(info, hopperPosition);
        case HopperConfigType.NRI_100_200:
            return getNRI_100_200CoinCountByPosition(info, hopperPosition);
    }
}

function getNRI_50_200CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_200);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_50);
    }
}

function getNRI_50_100CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_100);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_50);
    }
}

function getNRI_100_200CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_200);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_100);
    }
}

function getByCoinType(info: PayStationDetailInfo, coinType: CoinType) {
    return info.hopperCoinCount.find(c => c.coinType == coinType) ?? null;
}

function CommandList({
    peripheryConfig,
    cloudConnectorRequest,
}: {
    peripheryConfig: PeripheryConfig;
    cloudConnectorRequest: (args: CloudConnectorPeripheryRequest) => void;
}) {
    const { currentLogin } = useOperatorContext();
    const barrierPermanentlyOpen = peripheryConfig.events.some(
        event =>
            event.eventType ===
            CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN,
    );

    switch (peripheryConfig.peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
            return (
                <>
                    <ParkingPortalButton
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_UP_ONCE,
                                secondaryGate: false,
                                permanentlyOpen: null,
                            })
                        }
                        icon={<KeyboardControlKey />}
                    >
                        <OpenBarrier />
                    </ParkingPortalButton>
                    <ParkingPortalButton
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                                secondaryGate: null,
                                permanentlyOpen: !barrierPermanentlyOpen,
                            })
                        }
                        icon={<KeyboardCapslock />}
                    >
                        {barrierPermanentlyOpen ? (
                            <CloseBarrier />
                        ) : (
                            <BarrierPermanentlyOpen />
                        )}
                    </ParkingPortalButton>
                </>
            );
        case CloudConnectorPeripheryType.EXIT:
            return (
                <>
                    <ParkingPortalButton
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_UP_ONCE,
                                secondaryGate: false,
                                permanentlyOpen: null,
                            })
                        }
                        icon={<KeyboardControlKey />}
                    >
                        <OpenBarrier />
                    </ParkingPortalButton>
                    <ParkingPortalButton
                        onClick={() =>
                            cloudConnectorRequest({
                                operatorId: currentLogin.mandantId,
                                cloudConnectorId:
                                    peripheryConfig.cloudConnectorId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                                secondaryGate: null,
                                permanentlyOpen: !barrierPermanentlyOpen,
                            })
                        }
                        icon={<KeyboardCapslock />}
                    >
                        {barrierPermanentlyOpen ? (
                            <CloseBarrier />
                        ) : (
                            <BarrierPermanentlyOpen />
                        )}
                    </ParkingPortalButton>
                </>
            );
        case CloudConnectorPeripheryType.PAY_STATION:
            return null;
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
        case CloudConnectorPeripheryType.ACCESS:
            return <Navigate to={'..'} />;
    }
}

function OpenBarrier() {
    return (
        <Localized
            de="Barriere öffnen"
            fr="Ouvrir la barrière"
            it="Barriera aperta"
            en="Open barrier"
        />
    );
}

function BarrierPermanentlyOpen() {
    return (
        <Localized
            de="Barriere dauer auf"
            fr="Barrière ouverte en permanence"
            it="Barriera permanentemente aperta"
            en="Barrier permanently open"
        />
    );
}

function CloseBarrier() {
    return (
        <Localized
            de="Barriere schliessen"
            fr="Fermer la barrière"
            it="Barriera chiusa"
            en="Close barrier"
        />
    );
}

function RequestError({ open }: { open: boolean }) {
    if (open) {
        return (
            <Alert severity="error" sx={{ margin: '0 0 24px 0' }}>
                <Localized
                    de="Die Aktion konnte nicht ausgeführt werden."
                    fr="L'action n'a pas pu être réalisée."
                    it="Non è stato possibile eseguire l'azione."
                    en="The action could not be carried out."
                />
            </Alert>
        );
    }
    return null;
}
