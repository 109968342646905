import { useNavigate, useParams } from 'react-router-dom';
import {
    ProductTemplateListItem,
    ProductTypeAppStatusIcon,
    productTypeAppStatusText,
    useProductTemplateOutlet,
} from './OperatorProductTypes.tsx';
import {
    Box,
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
} from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import { DesignServices } from '@mui/icons-material';
import {
    BlockLabel,
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { intervalDurationName } from 'product-shared/product-template/SubscriptionIntervalDuration.ts';
import { makeLocalizedText } from 'dg-web-shared/lib/Localized.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { MissingPermissionsText } from '../products/OperatorProductDetailModal.tsx';
import {
    CalendarDayReservationConfig,
    ProductTemplateConfig,
    ProductTemplateConfigType,
    SubscriptionConfig,
} from 'product-shared/product-template/ProductTemplate.ts';
import { useCaseNameFromConfigType } from 'product-shared/product-template/ProductTemplateUseCase.ts';
import { quotaPartitionConfigText } from 'dg-web-shared/model/QuotaConfig.ts';
import { OperatorQuotaOutlet } from '../quotas/OperatorQuotasDetail.tsx';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { QuotaPartitionInfo } from '../common/state/PermitTypeState.ts';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';

interface ProductTemplateDetail extends ProductTemplateListItem {
    validFrom: string;
    quotaPartitionInfo: QuotaPartitionInfo | null;
}

export function OperatorProductTypesDetailModal() {
    const { contractTemplateId } = useParams();
    if (!contractTemplateId) {
        throw new Error('No contractTemplateId found');
    }
    const [productTemplateDetailState, productTemplateDetailRefetch] =
        useServerFetch<
            ProductTemplateDetail,
            { contractTemplateId: string },
            null
        >(
            ({ contractTemplateId }) => ({
                url: `/ui-api/operator-account/parkingabo/product-template-detail/${contractTemplateId}`,
            }),
            { contractTemplateId: contractTemplateId },
        );

    return (
        <>
            <OperatorRoutedModal
                variant={ModalVariant.STANDARD}
                backUrl={'/parkingabo/product-types'}
                render={() => (
                    <OperatorAsyncLoadedSection
                        requestState={productTemplateDetailState}
                        render={productTemplateDetail => (
                            <OperatorProductTypesDetail
                                productTemplateDetail={productTemplateDetail}
                            />
                        )}
                    />
                )}
            />
            <OperatorQuotaOutlet
                context={{
                    refetch: productTemplateDetailRefetch,
                }}
            />
        </>
    );
}

function OperatorProductTypesDetail({
    productTemplateDetail,
}: {
    productTemplateDetail: ProductTemplateDetail;
}) {
    const { currentLogin } = useOperatorContext();
    const quotasReadOnly = !currentLogin.permissions.quotasWrite;
    const navigate = useNavigate();
    const localize = makeLocalizedText(useOperatorLanguage());
    const { showDtaAccount } = useProductTemplateOutlet();
    const config = productTemplateDetail.productTemplateConfig;
    const isOffstreet = productTemplateDetail.parkingNames.length > 0;
    const quotaInfo = productTemplateDetail.quotaPartitionInfo;

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    Icon={DesignServices}
                    title={<Localized {...config.productName} />}
                    editMode={false}
                    buttons={
                        <HeaderActionCloseButtons
                            dropdownItems={
                                quotaInfo != null
                                    ? [
                                          {
                                              key: 'editQuota',
                                              label: (
                                                  <Localized
                                                      de="Kontingent bearbeiten"
                                                      fr="Modifier contingent"
                                                      it="Modifica contingente"
                                                      en="Edit quota"
                                                  />
                                              ),
                                              onClickCallback: () => {
                                                  if (quotaInfo == null) {
                                                      throw new Error(
                                                          'Quota can not be edited!',
                                                      );
                                                  }
                                                  navigate(
                                                      `quotas/${quotaInfo.contractQuotaPartitionId}`,
                                                  );
                                              },
                                              disabled: quotasReadOnly,
                                              tooltip: quotasReadOnly ? (
                                                  <MissingPermissionsText />
                                              ) : undefined,
                                          },
                                      ]
                                    : null
                            }
                            onClose={() =>
                                navigate('/parkingabo/product-types')
                            }
                        />
                    }
                />
            }
            body={
                <FormContentLayout>
                    <FormLeftColumn>
                        <TextField
                            disabled
                            label={
                                <Localized
                                    de="Bezeichnung"
                                    fr="Description"
                                    it="Descrizione"
                                    en="Description"
                                />
                            }
                            value={localize(config.productName)}
                            sx={{
                                color: theme => theme.palette.common?.white,
                            }}
                        />
                        <TextField
                            disabled
                            multiline
                            label={
                                <Localized
                                    de="Zusatzinformationen"
                                    fr="Informations complémentaires"
                                    it="Informazioni supplementari"
                                    en="Additional information"
                                />
                            }
                            value={
                                config.additionalInfo
                                    ? localize(config.additionalInfo)
                                    : EmDash
                            }
                        />
                        <TextField
                            disabled
                            label={
                                <Localized
                                    de="Use case"
                                    fr="Cas d'utilisation"
                                    it="Caso d'uso"
                                    en="Use case"
                                />
                            }
                            value={localize(
                                useCaseNameFromConfigType(config.configType),
                            )}
                        />
                        {isOffstreet ? (
                            <TextField
                                disabled
                                label={
                                    productTemplateDetail.parkingNames.length ==
                                    1 ? (
                                        <Localized
                                            de="Parking"
                                            fr="Parking"
                                            it="Parcheggio"
                                            en="Parking"
                                        />
                                    ) : (
                                        <Localized
                                            de="Parkings"
                                            fr="Parkings"
                                            it="Parcheggi"
                                            en="Parkings"
                                        />
                                    )
                                }
                                value={productTemplateDetail.parkingNames.join(
                                    '\n',
                                )}
                            />
                        ) : (
                            <TextField
                                disabled
                                label={
                                    productTemplateDetail.enforcedZoneNames
                                        .length == 1 ? (
                                        <Localized
                                            de="Zone"
                                            fr="Zone"
                                            it="Zona"
                                            en="Zone"
                                        />
                                    ) : (
                                        <Localized
                                            de="Zonen"
                                            fr="Zones"
                                            it="Zone"
                                            en="Zones"
                                        />
                                    )
                                }
                                value={productTemplateDetail.enforcedZoneNames.join(
                                    '\n',
                                )}
                            />
                        )}
                    </FormLeftColumn>
                    <FormRightColumn>
                        <TextField
                            disabled
                            label={
                                <Localized
                                    de="App-Kauf"
                                    fr="Achat dans l'app"
                                    it="Acquisto nell'app"
                                    en="App purchase"
                                />
                            }
                            value={localize(
                                productTypeAppStatusText(
                                    productTemplateDetail.appPurchaseVisibility,
                                    productTemplateDetail.validityStart,
                                ),
                            )}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ProductTypeAppStatusIcon
                                            appPurchaseVisibility={
                                                productTemplateDetail.appPurchaseVisibility
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {showDtaAccount && (
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Gruppe"
                                        fr="Groupe"
                                        it="Gruppo"
                                        en="Group"
                                    />
                                }
                                value={
                                    productTemplateDetail.dtaAccountDescription
                                }
                            />
                        )}
                        <ProductTypePrice config={config} />
                        {quotaInfo && (
                            <TextField
                                disabled
                                multiline
                                label={
                                    <Localized
                                        de="Kontingente"
                                        fr="Contingents"
                                        it="Contingenti"
                                        en="Quotas"
                                    />
                                }
                                value={quotaInfo.quotaConfigs
                                    .map(quotaConfig =>
                                        localize({
                                            ...quotaPartitionConfigText(
                                                quotaConfig,
                                                isOffstreet,
                                            ),
                                        }),
                                    )
                                    .join('\n')}
                            />
                        )}
                        {quotaInfo && quotaInfo.otherProducts.length > 0 && (
                            <TextField
                                disabled
                                multiline
                                label={
                                    <Localized
                                        de="Andere Produkttypen im Kontingent"
                                        fr="Autres types de produits dans le contingent"
                                        it="Altri tipi di prodotti nel contingente"
                                        en="Other product types in the quota"
                                    />
                                }
                                value={quotaInfo.otherProducts
                                    .map(product => localize(product.name))
                                    .join('\n')}
                            />
                        )}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                            }}
                        >
                            <BlockLabel
                                label={
                                    <Localized
                                        de="Aktualisiert am"
                                        fr="Actualisé le"
                                        it="Aggiornato il"
                                        en="Updated on"
                                    />
                                }
                            >
                                {Formatter.dayMonthYearHourMinute(
                                    DateTime.fromISO(
                                        productTemplateDetail.validFrom,
                                    ),
                                )}
                            </BlockLabel>
                            <BlockLabel label="ID">
                                {productTemplateDetail.contractTemplateId}
                            </BlockLabel>
                        </Box>
                    </FormRightColumn>
                </FormContentLayout>
            }
        />
    );
}

function ProductTypePrice({
    config,
}: {
    config: ProductTemplateConfig;
}): JSX.Element | null {
    switch (config.configType) {
        case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE:
        case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED:
        case ProductTemplateConfigType.SUBSCRIPTION_ENFORCED_APP:
        case ProductTemplateConfigType.SUBSCRIPTION_BARRIER_GATE_APP:
            return <ProductTypeSubscriptionPrice config={config} />;
        case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_BARRIER_GATE:
        case ProductTemplateConfigType.CALENDAR_DAY_RESERVATION_ENFORCED:
            return <ProductTypeReservationPrice config={config} />;
        case ProductTemplateConfigType.GUEST_RESERVATION_ENFORCED:
        case ProductTemplateConfigType.GUEST_RESERVATION_BARRIER_GATE:
        case ProductTemplateConfigType.PERMIT_UNTIL_REVOCATION_BARRIER_GATE:
        case ProductTemplateConfigType.PERMIT_FREE_FIXED_DURATION_BARRIER_GATE:
        case ProductTemplateConfigType.ALL_ACCESS_BARRIER_GATE:
            return null;
    }
}

function ProductTypeSubscriptionPrice({
    config,
}: {
    config: SubscriptionConfig;
}) {
    const localize = makeLocalizedText(useOperatorLanguage());
    return (
        <>
            <TextField
                disabled
                label={
                    <Localized
                        de="Preis / Einhheit"
                        fr="Prix / Unité"
                        it="Prezzo / Unità"
                        en="Price / Unit"
                    />
                }
                value={`CHF ${config.intervalConfig.intervalPrice} / ${localize(
                    intervalDurationName(
                        config.intervalConfig.intervalDuration,
                    ),
                )}`}
            />
            <TextField
                disabled
                label={
                    <Localized
                        de="Max. Einheiten in der Zukunft"
                        fr="Max. unités dans le futur"
                        it="Max. unità nel futuro"
                        en="Max. units in the future"
                    />
                }
                value={config.intervalConfig.maxIntervalsPurchaseAheadInFuture}
            />
        </>
    );
}

function ProductTypeReservationPrice({
    config,
}: {
    config: CalendarDayReservationConfig;
}) {
    const localize = makeLocalizedText(useOperatorLanguage());
    const timeAndPriceConfig = config.timeAndPriceConfig;
    const start = Formatter.hourMinute(
        DateTime.fromISO(timeAndPriceConfig.validityStart),
    );
    const end = Formatter.hourMinute(
        DateTime.fromISO(timeAndPriceConfig.validityEnd),
    );
    const mondayValue = timeAndPriceConfig.priceConfig.monday;
    const constantPrice = Object.values(timeAndPriceConfig.priceConfig).every(
        price => price == mondayValue,
    );

    return (
        <>
            <Stack spacing={2} direction="row">
                <TextField
                    disabled
                    label={
                        <Localized
                            de="Startzeit"
                            fr="Heure de début"
                            it="Ora d'inizio"
                            en="Start time"
                        />
                    }
                    value={start}
                />
                <TextField
                    disabled
                    label={
                        <Localized
                            de="Endzeit"
                            fr="Heure de fin"
                            it="Ora di fine"
                            en="End time"
                        />
                    }
                    value={end}
                />
            </Stack>
            <InputLabel>
                <Localized de="Preis" fr="Prix" it="Prezzo" en="Price" />
            </InputLabel>
            {constantPrice ? (
                <DayPriceRow
                    day={localize({
                        de: 'Montag-Sonnntag',
                        fr: 'Lundi-Dimanche',
                        it: 'Lunedì-Domenica',
                        en: 'Monday-Sunday',
                    })}
                    price={mondayValue}
                />
            ) : (
                <Box
                    sx={{
                        marginTop: '0px !important',
                    }}
                >
                    <DayPriceRow
                        day={localize({
                            de: 'Montag',
                            fr: 'Lundi',
                            it: 'Lunedì',
                            en: 'Monday',
                        })}
                        price={timeAndPriceConfig.priceConfig.monday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Dienstag',
                            fr: 'Mardi',
                            it: 'Martedì',
                            en: 'Tuesday',
                        })}
                        price={timeAndPriceConfig.priceConfig.tuesday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Mittwocht',
                            fr: 'Mercredi',
                            it: 'Mercoledì',
                            en: 'Wednesday',
                        })}
                        price={timeAndPriceConfig.priceConfig.wednesday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Donnerstag',
                            fr: 'Jeudi',
                            it: 'Giovedì',
                            en: 'Thursday',
                        })}
                        price={timeAndPriceConfig.priceConfig.thursday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Freitag',
                            fr: 'Vendredi',
                            it: 'Venerdì',
                            en: 'Friday',
                        })}
                        price={timeAndPriceConfig.priceConfig.friday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Samstag',
                            fr: 'Samedi',
                            it: 'Sabato',
                            en: 'Saturday',
                        })}
                        price={timeAndPriceConfig.priceConfig.saturday}
                    />
                    <DayPriceRow
                        day={localize({
                            de: 'Sonntag',
                            fr: 'Dimanche',
                            it: 'Domenica',
                            en: 'Sunday',
                        })}
                        price={timeAndPriceConfig.priceConfig.sunday}
                    />
                </Box>
            )}
        </>
    );
}

function DayPriceRow({ day, price }: { day: string; price: number | null }) {
    const priceString =
        price != null ? NumberFormatter.numberToPrice(price, true) : null;

    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{
                color: theme => theme.palette.blue.main,
                fontWeight: '500',
                fontSize: '16px',
                marginTop: '0px !important',
            }}
        >
            <Box
                sx={{
                    width: '184px',
                }}
            >
                {day}
            </Box>
            {priceString ? (
                <Box>{priceString}</Box>
            ) : (
                <Box
                    sx={{
                        fontStyle: 'italic',
                        color: theme => theme.palette.grey['500'],
                    }}
                >
                    <Localized
                        de="Nicht verfügbar"
                        fr="Pas disponible"
                        it="Non disponibile"
                        en="Not available"
                    />
                </Box>
            )}
        </Stack>
    );
}
