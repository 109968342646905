import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import React, { FormEventHandler } from 'react';

export function FormLeftColumn({
    children,
}: {
    children: React.ReactNode | React.ReactNodeArray;
}) {
    const theme = useTheme();

    return (
        <Stack
            spacing={3}
            style={{
                flexBasis: '50%',
                paddingRight: theme.spacing(3),
            }}
        >
            {children}
        </Stack>
    );
}

export function FormRightColumn({
    children,
}: {
    children: React.ReactNode | React.ReactNodeArray;
}) {
    const theme = useTheme();

    return (
        <Stack
            spacing={3}
            style={{
                flexBasis: '50%',
                borderLeft: `solid 1px ${theme.palette.divider}`,
                paddingLeft: theme.spacing(3),
                height: '100%',
            }}
        >
            {children}
        </Stack>
    );
}

export function FormContentLayout({
    children,
    onSubmit,
}: {
    children: React.ReactNode;
    onSubmit?: FormEventHandler<HTMLFormElement>;
}) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
            }}
            component={onSubmit ? 'form' : 'div'}
            onSubmit={onSubmit}
        >
            {children}
        </Box>
    );
}

export function BlockLabel({
    label,
    children,
}: {
    label: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <div>
            <Typography
                component="span"
                variant="overline"
                color="primary.main"
            >
                {label}:{' '}
            </Typography>
            <Typography
                component="span"
                fontSize={14}
                color="primary.main"
                fontWeight={500}
            >
                {children}
            </Typography>
        </div>
    );
}
