import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import {
    createBrowserRouter,
    Navigate,
    RouteObject,
    RouterProvider,
} from 'react-router-dom';
import { OperatorAppRoutes } from '../config/OperatorRoutingConfig.tsx';
import { css } from '@emotion/css';
import { CssBaseline } from '@mui/material';
import { OperatorThemeProvider } from './OperatorThemeProvider.tsx';
import {
    BaseLayoutAndData,
    OperatorContextProvider,
    OperatorContextState,
} from './BaseLayoutAndData.tsx';
import { AlternativeLanding } from '../../landing-page/AlternativeLanding.tsx';
import { ResetPassword } from '../../reset-password/ResetPassword.tsx';
import { ConnectedChangePasswordController } from '../../password-change-token/ChangePasswordController.tsx';
import { OperatorMasterData } from '../../operator-master-data/components/OperatorMasterData.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { Zones } from '../../zones/components/Zones.tsx';
import { Parkings } from '../../parkings/components/Parkings.tsx';
import { Tariffs } from '../../tariffs/components/Tariffs.tsx';
import { OperatorLoginsList } from '../../operator-logins/components/OperatorLoginsList.tsx';
import { EnforcementLog } from '../../enforcement-log/EnforcementLog.tsx';
import { OperatorStatisticsRoute } from '../../statistics/OperatorStatisticsRoute.tsx';
import {
    OperatorParkingaboAddUser,
    OperatorParkingaboInviteUser,
    OperatorParkingaboUsers,
} from '../../parkingabo-users/OperatorParkingaboUsers.tsx';
import {
    OperatorProductDetail,
    OperatorProducts,
} from '../../products/OperatorProducts.tsx';
import { TomsOverview } from '../../tom/components/TomsOverview.tsx';
import { ParkTransactionsOverview } from '../../park-transactions-tom/components/ParkTransactionsOverview.tsx';
import { UsbSticks } from '../../usb-sticks/components/UsbSticks.tsx';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { IFrame } from './IFrame.tsx';
import { ClearancePermitList } from '../../clearance-permit-list/components/ClearancePermitList.tsx';
import { ClearanceRequests } from '../../clearance-requests/ClearanceRequests.tsx';
import { ActivityStatement } from '../../activity-statement/components/ActivityStatement.tsx';
import { CustomerAccounts } from '../../customer-account/components/CustomerAccounts.tsx';
import { OperatorParkingaboUsersProductsAdd } from '../../parkingabo-users/OperatorParkingaboUsersProductsAdd.tsx';
import { OperatorParkingaboUsersDetails } from '../../parkingabo-users/OperatorParkingaboUsersDetail.tsx';
import {
    OperatorParkingaboUsersVehicleAddForm,
    OperatorParkingaboUsersVehicleEditForm,
} from '../../parkingabo-users/OperatorParkingaboUsersVehicleForm.tsx';
import {
    OperatorParkingaboUserProductDetail,
    OperatorParkingaboUsersProducts,
} from '../../parkingabo-users/OperatorParkingaboUsersProducts.tsx';
import * as AuthenticationState from '../state/AuthenticationState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { Spinner } from '../../ui/loaders/Spinner.tsx';
import { LandingPage } from '../../landing-page/LandingPage.tsx';
import React from 'react';
import { OperatorProductTypes } from '../../product-types/OperatorProductTypes.tsx';
import { OperatorProductTypesDetailModal } from '../../product-types/OperatorProductTypesDetailModal.tsx';
import { OperatorUserCategories } from '../../user-categories/OperatorUserCategories.tsx';
import { OperatorQuotasDetail } from '../../quotas/OperatorQuotasDetail.tsx';
import { OperatorParkingaboUserEditForm } from '../../parkingabo-users/OperatorParkingaboUsersForm.tsx';
import { OperatorParkingaboUsersVehicles } from '../../parkingabo-users/OperatorParkingaboUsersVehicles.tsx';
import { OperatorParkingaboUsersAccountStatement } from '../../parkingabo-users/OperatorParkingaboUsersAccountStatement.tsx';
import { OperatorOffstreetTransactionTicket } from '../../offstreet-transactions/OperatorOffstreetTransactionTicket.tsx';
import { OnstreetTransactions } from '../../park-transactions-onstreet/OnstreetTransaction.tsx';
import { OperatorOffstreetTransactionEntryWithoutExit } from '../../offstreet-transactions/OperatorOffstreetTransactionEntryWithoutExit.tsx';
import { OperatorOffstreetTransactionContract } from '../../offstreet-transactions/OperatorOffstreetTransactionContract.tsx';
import { OperatorOffstreetTransactionsRouteLayout } from '../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { PermitTypes } from '../../permit-types/components/PermitTypes.tsx';
import { SpecialReports } from '../../special-reports/SpecialReports.tsx';
import {
    CloudConnectorPeripheriesOverviewDesktop,
    CloudConnectorPeripheriesOverviewMobile,
} from '../../cloud-connector/CloudConnectorPeripheriesOverview.tsx';
import { MobileLoginPage } from '../../mobile/login-page/MobileLoginPage.tsx';
import { MobileLayout } from './MobileLayout.tsx';
import { MobileHomePage } from '../../mobile/HomePage.tsx';
import { ParkingPortalSettings } from '../../mobile/ParkingPortalSettings.tsx';
import { CloudConnectorPeripheriesDetails } from '../../cloud-connector/CloudConnectoPeripheriesDetail.tsx';
import { PayStationStatements } from '../../cloud-connector/pay-station/PayStationStatements.tsx';
import { PayStationCollections } from '../../cloud-connector/pay-station/PayStationCollections.tsx';
import { PeripheryHistory } from '../../periphery-history/PeripheryHistory.tsx';
import { LostTicketRoute } from '../../lost-ticket/LostTicketRoute.tsx';
import { CloudConnectorRecoder } from '../../cloud-connector/CloudConnectorRecoder.tsx';
import { CloudConnectorRecodeTicket } from '../../cloud-connector/CloudConnectorRecodeTicket.tsx';
import { CloudConnectorRecodeTicketCondition } from '../../cloud-connector/CloudConnectorRecodeTicketCondition.tsx';
import { CloudConnectorRecodeTicketConditionsOutlet } from '../../cloud-connector/CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { ParkingportalPrivacy } from '../../privacy/Privacy.tsx';
import { CloudConnectorRecodeSuccessResponse } from '../../cloud-connector/CloudConnectorRecodeResponse.tsx';
import { CloudConnectorCounterOverview } from '../../cloud-connector/CloudConnectorCounterOverview.tsx';
import { PayStationManualCorrection } from '../../cloud-connector/pay-station/PayStationManualCorrection.tsx';
import { CloudConnectorCounterDetail } from '../../cloud-connector/CloudConnectorCounterDetail.tsx';

const publicRoutes = [
    { element: <LandingPage />, index: true },
    {
        path: '/reset-password',
        element: (
            <AlternativeLanding>
                <ResetPassword />
            </AlternativeLanding>
        ),
    },
    {
        path: '/password-change-token',
        element: (
            <AlternativeLanding>
                <ConnectedChangePasswordController />
            </AlternativeLanding>
        ),
    },
    {
        path: '/mobile',
        children: [
            {
                index: true,
                element: <Navigate to="login" />,
            },
            {
                path: '*',
                element: <Navigate to=".." />,
            },
            {
                path: 'login',
                element: <MobileLoginPage />,
            },
        ],
    },
    {
        path: '/lost-ticket/:code?',
        element: <LostTicketRoute />,
    },
    {
        path: '/privacy',
        element: <ParkingportalPrivacy />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
];

function OperatourRouterProvider() {
    const { storeState, store } = useStore(s => ({
        settings: new SettingsState.StateSlice(s).state,
        authentication: new AuthenticationState.StateSlice(s).state,
        operatorData: OperatorDataState.get(s),
        currentLogin: CurrentOperatorLoginState.get(s),
    }));
    const { authentication, operatorData, currentLogin } = storeState;

    if (
        authentication.status.unknown ||
        operatorData.pending ||
        currentLogin.pending
    ) {
        return <Spinner />;
    }

    const opData = operatorData.data;
    const clData = currentLogin.data;
    if (authentication.status.unauthorized || authentication.status.unknown) {
        return <RouterProvider router={createBrowserRouter(publicRoutes)} />;
    } else {
        if (!opData || !clData) {
            return <div>error</div>;
        }
    }

    return (
        <RouterProvider
            router={createBrowserRouter([
                getAuthorizedRoutes({
                    store: store,
                    settings: storeState.settings,
                    authentication: authentication.status,
                    operatorData: opData,
                    currentLogin: clData,
                }),
                {
                    path: '*',
                    element: <Navigate to="/" replace />,
                },
            ])}
        />
    );
}

function getAuthorizedRoutes({
    store,
    settings,
    authentication,
    operatorData,
    currentLogin,
}: {
    store: Flux.Store;
} & OperatorContextState): RouteObject {
    return {
        element: (
            <OperatorContextProvider
                settings={settings}
                authentication={authentication}
                operatorData={operatorData}
                currentLogin={currentLogin}
            />
        ),
        children: [
            {
                element: <BaseLayoutAndData />,
                children: [
                    {
                        handle: {
                            title: (
                                <Localized
                                    de="Allgemein"
                                    fr="Général"
                                    en="Global"
                                    it="Generale"
                                />
                            ),
                        },
                        children: [
                            {
                                path: '/',
                                element: <OperatorMasterData />,
                                handle: {
                                    routeName: OperatorAppRoutes.Home,
                                },
                            },
                            {
                                path: '/activity-statement',
                                element: <ActivityStatement allState={store} />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ActivityStatement,
                                },
                            },
                            {
                                path: '/tariffs',
                                element: <Tariffs />,
                                handle: {
                                    routeName: OperatorAppRoutes.Tariffs,
                                },
                            },
                            {
                                path: '/logins',
                                element: (
                                    <OperatorLoginsList allState={store} />
                                ),
                                handle: {
                                    routeName: OperatorAppRoutes.Logins,
                                },
                            },
                            {
                                path: '/statistics',
                                element: <OperatorStatisticsRoute />,
                                handle: {
                                    routeName: OperatorAppRoutes.Statistics,
                                },
                            },
                        ],
                    },
                    {
                        handle: {
                            title: (
                                <Localized
                                    de="Onstreet"
                                    fr="Onstreet"
                                    en="Onstreet"
                                    it="Onstreet"
                                />
                            ),
                        },
                        children: [
                            {
                                path: '/onstreet-transactions',
                                element: <OnstreetTransactions />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.OnstreetTransactions,
                                },
                            },
                            {
                                path: '/tom-transactions',
                                element: (
                                    <ParkTransactionsOverview
                                        allState={store}
                                    />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.TomTransactions,
                                },
                            },
                            {
                                path: '/control-history',
                                element: <EnforcementLog />,
                                handle: {
                                    routeName: OperatorAppRoutes.ControlHistory,
                                },
                            },
                            {
                                path: '/zones',
                                element: <Zones />,
                                handle: {
                                    routeName: OperatorAppRoutes.Zones,
                                },
                            },
                            {
                                path: '/parking-meters',
                                element: <TomsOverview allState={store} />,
                                handle: {
                                    routeName: OperatorAppRoutes.ParkingMeters,
                                },
                            },
                            {
                                path: '/usb-sticks',
                                element: <UsbSticks />,
                                handle: {
                                    routeName: OperatorAppRoutes.UsbSticks,
                                },
                            },
                        ],
                    },
                    {
                        handle: {
                            title: (
                                <Localized
                                    de="Offstreet"
                                    fr="Offstreet"
                                    en="Offstreet"
                                    it="Offstreet"
                                />
                            ),
                        },
                        children: [
                            {
                                path: '/offstreet-transactions',
                                element: (
                                    <OperatorOffstreetTransactionsRouteLayout />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.OffstreetTransactions,
                                },
                                children: [
                                    {
                                        path: 'ticket/:ticketId/detail',
                                        element: (
                                            <OperatorOffstreetTransactionTicket />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.OffstreetTransactionsTicketDetail,
                                        },
                                    },
                                    {
                                        path: 'entry-without-exit/:entryWithoutExitId/detail',
                                        element: (
                                            <OperatorOffstreetTransactionEntryWithoutExit />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.OffstreetTransactionsEntryWithoutExitDetail,
                                        },
                                    },
                                    {
                                        path: 'contract/:contractId/detail',
                                        element: (
                                            <OperatorOffstreetTransactionContract />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.OffstreetTransactionsContractDetail,
                                        },
                                    },
                                ],
                            },
                            {
                                path: '/device-history',
                                element: <PeripheryHistory />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.PeripheryHistory,
                                },
                            },
                            {
                                path: '/pay-station-statement',
                                element: <PayStationStatements />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.PayStationStatement,
                                },
                            },
                            {
                                path: '/pay-station-collections',
                                element: <PayStationCollections />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.PayStationCollections,
                                },
                            },
                            {
                                path: '/parkings',
                                element: <Parkings />,
                                handle: {
                                    routeName: OperatorAppRoutes.Parkings,
                                },
                            },
                            {
                                path: '/offstreet-devices',
                                element: (
                                    <CloudConnectorPeripheriesOverviewDesktop />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorPeripheries,
                                },
                                children: [
                                    {
                                        path: ':cloudConnectorId/:peripheryType/:onPremiseId/detail',
                                        element: (
                                            <CloudConnectorPeripheriesDetails />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.CloudConnectorPeripheriesDetail,
                                        },
                                        children: [
                                            {
                                                path: ':coinType/manual-correction',
                                                element: (
                                                    <PayStationManualCorrection />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.CloudConnectorPayStationManualCorrection,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/counters',
                                element: <CloudConnectorCounterOverview />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorCounterOverview,
                                },
                                children: [
                                    {
                                        path: ':zoneBarrierGateAreaCounterId',
                                        element: (
                                            <CloudConnectorCounterDetail />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.CloudConnectorCounterDetail,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        handle: {
                            title: (
                                <Localized
                                    de="Parkingpay"
                                    fr="Parkingpay"
                                    en="Parkingpay"
                                    it="Parkingpay"
                                />
                            ),
                        },
                        children: [
                            {
                                path: '/clearance-permit-list',
                                element: (
                                    <ClearancePermitList allState={store} />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ClearancesPermitsList,
                                },
                            },
                            {
                                path: '/clearance-requests',
                                element: <ClearanceRequests />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ClearanceRequests,
                                },
                            },
                            {
                                path: '/customer-accounts',
                                element: <CustomerAccounts allState={store} />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CustomerAccounts,
                                },
                            },
                            {
                                path: '/special-reports',
                                element: <SpecialReports allState={store} />,
                                handle: {
                                    routeName: OperatorAppRoutes.SpecialReports,
                                },
                            },
                            {
                                path: '/permit-types',
                                element: <PermitTypes />,
                                handle: {
                                    routeName: OperatorAppRoutes.PermitTypes,
                                },
                                children: [
                                    {
                                        path: 'quotas/:contractQuotaPartitionId',
                                        element: <OperatorQuotasDetail />,
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboQuotasDetail,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/parkingabo',
                        handle: {
                            title: (
                                <Localized
                                    de="Parkingabo"
                                    fr="Parkingabo"
                                    en="Parkingabo"
                                    it="Parkingabo"
                                />
                            ),
                        },
                        children: [
                            {
                                path: 'users',
                                element: <OperatorParkingaboUsers />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ParkingaboUsers,
                                },
                                children: [
                                    {
                                        path: 'add/tenant/:tenantId',
                                        element: <OperatorParkingaboAddUser />,
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboUsersAdd,
                                        },
                                    },
                                    {
                                        path: 'invite/tenant/:tenantId',
                                        element: (
                                            <OperatorParkingaboInviteUser />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboUsersInvite,
                                        },
                                    },
                                    {
                                        path: ':customerNr',
                                        element: (
                                            <OperatorParkingaboUsersDetails />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboUsersDetail,
                                        },
                                        children: [
                                            {
                                                path: 'detail',
                                                element: (
                                                    <OperatorParkingaboUserEditForm />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.ParkingaboUsersDetail,
                                                },
                                            },
                                            {
                                                path: 'products',
                                                element: (
                                                    <OperatorParkingaboUsersProducts />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.ParkingaboUsersProducts,
                                                },
                                                children: [
                                                    {
                                                        path: 'add',
                                                        element: (
                                                            <OperatorParkingaboUsersProductsAdd />
                                                        ),
                                                        handle: {
                                                            routeName:
                                                                OperatorAppRoutes.ParkingaboUsersProductsAdd,
                                                        },
                                                    },
                                                    {
                                                        path: ':contractId/detail',
                                                        element: (
                                                            <OperatorParkingaboUserProductDetail />
                                                        ),
                                                        handle: {
                                                            routeName:
                                                                OperatorAppRoutes.ParkingaboUsersProductsDetail,
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'vehicles',
                                                element: (
                                                    <OperatorParkingaboUsersVehicles />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.ParkingaboUsersVehicles,
                                                },
                                                children: [
                                                    {
                                                        path: 'add',
                                                        element: (
                                                            <OperatorParkingaboUsersVehicleAddForm />
                                                        ),
                                                        handle: {
                                                            routeName:
                                                                OperatorAppRoutes.ParkingaboUsersVehiclesAdd,
                                                        },
                                                    },
                                                    {
                                                        path: ':vehicleId',
                                                        element: (
                                                            <OperatorParkingaboUsersVehicleEditForm />
                                                        ),
                                                        handle: {
                                                            routeName:
                                                                OperatorAppRoutes.ParkingaboUsersVehiclesDetail,
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                path: 'account-statement',
                                                element: (
                                                    <OperatorParkingaboUsersAccountStatement />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.ParkingaboUsersAccountStatement,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'products',
                                element: <OperatorProducts />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ParkingaboProducts,
                                },
                                children: [
                                    {
                                        path: ':contractId/detail',
                                        element: <OperatorProductDetail />,
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboProductsDetail,
                                        },
                                    },
                                ],
                            },

                            {
                                path: 'product-types',
                                element: <OperatorProductTypes />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ParkingaboProductTypes,
                                },
                                children: [
                                    {
                                        path: ':contractTemplateId/detail',
                                        element: (
                                            <OperatorProductTypesDetailModal />
                                        ),
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.ParkingaboProductTypesDetail,
                                        },
                                        children: [
                                            {
                                                path: 'quotas/:contractQuotaPartitionId',
                                                element: (
                                                    <OperatorQuotasDetail />
                                                ),
                                                handle: {
                                                    routeName:
                                                        OperatorAppRoutes.ParkingaboQuotasDetail,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'user-categories',
                                element: <OperatorUserCategories />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.ParkingaboUserCategories,
                                },
                            },
                        ],
                    },
                    {
                        handle: {
                            title: (
                                <Localized
                                    de="Externe Module"
                                    fr="Modules externes"
                                    en="External Modules"
                                    it="Moduli esterni"
                                />
                            ),
                        },
                        children: [
                            {
                                path: '/parkmonitor',
                                element: (
                                    <IframeComponent
                                        url={(lng: string) =>
                                            `https://parkmonitor.parkingportal.ch/PUBLIC/?langue=${parkmonitorLanguages[lng]}`
                                        }
                                    />
                                ),
                                handle: {
                                    routeName: OperatorAppRoutes.Parkmonitor,
                                },
                            },
                            {
                                path: '/mi-office',
                                element: (
                                    <IframeComponent
                                        url={() =>
                                            'https://mioffice.parkingportal.ch//mioffice_taxomex/Account/Login'
                                        }
                                    />
                                ),
                                handle: {
                                    routeName: OperatorAppRoutes.MiOffice,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: '/mobile',
                element: <MobileLayout />,
                handle: {
                    isMobile: true,
                },
                children: [
                    {
                        index: true,
                        element: <MobileHomePage />,
                        handle: {
                            routeName: OperatorAppRoutes.MobileHomePage,
                        },
                    },
                    {
                        path: 'offstreet-devices',
                        element: <CloudConnectorPeripheriesOverviewMobile />,
                        handle: {
                            routeName:
                                OperatorAppRoutes.MobileCloudConnectorPeripheries,
                        },
                        children: [
                            {
                                path: ':cloudConnectorId/:peripheryType/:onPremiseId/detail',
                                element: <CloudConnectorPeripheriesDetails />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorPeripheriesDetail,
                                },
                                children: [
                                    {
                                        path: ':coinType/manual-correction',
                                        element: <PayStationManualCorrection />,
                                        handle: {
                                            routeName:
                                                OperatorAppRoutes.CloudConnectorPayStationManualCorrection,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'counter',
                        element: <CloudConnectorCounterOverview mobile />,
                        handle: {
                            routeName:
                                OperatorAppRoutes.MobileCloudCounterOverview,
                        },
                        children: [
                            {
                                path: ':zoneBarrierGateAreaCounterId',
                                element: <CloudConnectorCounterDetail mobile />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.MobileCloudCounterDetail,
                                },
                            },
                        ],
                    },
                    {
                        path: 'recode',
                        element: <CloudConnectorRecoder />,
                        handle: {
                            routeName: OperatorAppRoutes.CloudConnectorRecode,
                        },
                        children: [
                            {
                                path: 'success',
                                element: (
                                    <CloudConnectorRecodeSuccessResponse />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorRecodeSuccessResponse,
                                },
                            },
                            {
                                path: 'error/:type?',
                                element: <CloudConnectorCounterDetail />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorRecodeErrorResponse,
                                },
                            },
                        ],
                    },
                    {
                        path: 'recode/ticket/:ticketScan',
                        element: <CloudConnectorRecodeTicketConditionsOutlet />,
                        children: [
                            {
                                index: true,
                                element: <CloudConnectorRecodeTicket />,
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorRecodeTicket,
                                },
                            },
                            {
                                path: 'recodeCondition/:recodeConditionId',
                                element: (
                                    <CloudConnectorRecodeTicketCondition />
                                ),
                                handle: {
                                    routeName:
                                        OperatorAppRoutes.CloudConnectorRecodeTicketCondition,
                                },
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        element: <ParkingPortalSettings />,
                        handle: {
                            routeName:
                                OperatorAppRoutes.MobileCloudConnectorSettings,
                        },
                    },
                    {
                        path: '*',
                        element: <Navigate to=".." replace={true} />,
                        handle: {
                            routeName: OperatorAppRoutes.MobileHomePage,
                        },
                    },
                ],
            },
            {
                path: '/privacy',
                element: <ParkingportalPrivacy />,
                handle: {
                    routeName: OperatorAppRoutes.Privacy,
                },
            },
        ],
    };
}

export class App extends React.Component<{ allState: Flux.Store }> {
    render() {
        return (
            <div className={css({ fontFamily: 'Roboto', height: '100%' })}>
                <Flux.StoreContext.Provider value={this.props.allState}>
                    <OperatorThemeProvider>
                        <CssBaseline>
                            <OperatourRouterProvider />
                        </CssBaseline>
                    </OperatorThemeProvider>
                </Flux.StoreContext.Provider>
            </div>
        );
    }
}

const parkmonitorLanguages: { [key: string]: string } = {
    de: 'ger',
    fr: 'fre',
    it: 'ita',
};

const IframeComponent = Flux.selectState<
    { url: (lng: string) => string },
    { settings: SettingsState.State }
>(
    store => ({
        settings: new SettingsState.StateSlice(store).state,
    }),
    p => {
        return <IFrame url={p.url} language={p.settings.language} />;
    },
);
