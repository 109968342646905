import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { MaterialHeader } from '../layout/components/MaterialHeader.tsx';
import { MaterialTitle } from '../layout/components/MaterialTitle.tsx';
import {
    CheckCircleOutline,
    DesignServices,
    RemoveCircleOutline,
    Schedule,
} from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { makeLocalizedText } from 'dg-web-shared/lib/Localized.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { AppPurchaseVisibility } from 'product-shared/product-template/ProductTemplateEnums.ts';
import { ProductTemplateConfig } from 'product-shared/product-template/ProductTemplate.ts';
import { EmptyResults } from '../shared-mui-components/ListMessages.tsx';
import { SeparationBar } from '../layout/components/SeparationBar.tsx';
import { useCaseNameFromConfigType } from 'product-shared/product-template/ProductTemplateUseCase.ts';

export interface ProductTemplateListItem {
    contractTemplateId: number;
    productTemplateConfig: ProductTemplateConfig;
    appPurchaseVisibility: AppPurchaseVisibility | null;
    validityStart: string | null;
    parkingNames: string[];
    enforcedZoneNames: string[];
    dtaAccountDescription: string;
}

interface ProductTemplateResult {
    productTemplateList: ProductTemplateListItem[];
    showDtaAccount: boolean;
}

export function OperatorProductTypes() {
    const language = useOperatorLanguage();
    const [productTemplateResultState] = useServerFetch<
        ProductTemplateResult,
        { lang: string },
        null
    >(
        ({ lang }) => ({
            url: `/ui-api/operator-account/parkingabo/product-template-list/${lang}`,
        }),
        { lang: language },
    );

    return (
        <MainContent>
            <MaterialHeader editMode={false}>
                <MaterialTitle
                    Icon={DesignServices}
                    title={
                        <Localized
                            de="Produkttypen"
                            fr="Types de produits"
                            it="Tipi di prodotto"
                            en="Product types"
                        />
                    }
                />
            </MaterialHeader>
            <SeparationBar />
            <OperatorAsyncLoadedSection
                requestState={productTemplateResultState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                render={productTemplateResult => {
                    if (
                        productTemplateResult.productTemplateList.length === 0
                    ) {
                        return <EmptyResults hasFilter={false} />;
                    }
                    return (
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Bezeichnung"
                                                fr="Description"
                                                it="Descrizione"
                                                en="Description"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Use case"
                                                fr="Cas d'utilisation"
                                                it="Caso d'uso"
                                                en="Use case"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="App-Kauf"
                                                fr="Achat dans l'app"
                                                it="Acquisto nell'app"
                                                en="App purchase"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Zonen/Parkings"
                                                fr="zones/parkings"
                                                it="zone/parcheggi"
                                                en="Zones/parkings"
                                            />
                                        </TableCell>
                                        {productTemplateResult.showDtaAccount && (
                                            <TableCell>
                                                <Localized
                                                    de="Gruppe"
                                                    fr="Groupe"
                                                    it="Gruppo"
                                                    en="Group"
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productTemplateResult.productTemplateList.map(
                                        productTemplate => (
                                            <OperatorProductTypeRow
                                                key={
                                                    productTemplate.contractTemplateId
                                                }
                                                productTemplateListItem={
                                                    productTemplate
                                                }
                                                showDtaAccount={
                                                    productTemplateResult.showDtaAccount
                                                }
                                            />
                                        ),
                                    )}
                                </TableBody>
                            </Table>
                            <ProductTemplateOutlet
                                context={{
                                    showDtaAccount:
                                        productTemplateResult.showDtaAccount,
                                }}
                            />
                        </TableContainer>
                    );
                }}
            />
        </MainContent>
    );
}

function OperatorProductTypeRow({
    productTemplateListItem,
    showDtaAccount,
}: {
    productTemplateListItem: ProductTemplateListItem;
    showDtaAccount: boolean;
}) {
    const navigate = useNavigate();
    const configType = productTemplateListItem.productTemplateConfig.configType;
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() =>
                navigate(
                    `/parkingabo/product-types/${productTemplateListItem.contractTemplateId}/detail`,
                )
            }
        >
            <TableCell>
                <Localized
                    {...productTemplateListItem.productTemplateConfig
                        .productName}
                />
            </TableCell>
            <TableCell>
                <Localized {...useCaseNameFromConfigType(configType)} />
            </TableCell>
            <TableCell>
                <AppStatusIconWithHint
                    appPurchaseVisibility={
                        productTemplateListItem.appPurchaseVisibility
                    }
                    validityStart={productTemplateListItem.validityStart}
                />
            </TableCell>
            <TableCell>
                <OperatorProductTypeZoneNameShort
                    enforcedZoneNames={
                        productTemplateListItem.enforcedZoneNames
                    }
                    parkingNames={productTemplateListItem.parkingNames}
                />
            </TableCell>
            {showDtaAccount && (
                <TableCell>
                    {productTemplateListItem.dtaAccountDescription}
                </TableCell>
            )}
        </TableRow>
    );
}

export function useProductTemplateOutlet() {
    return useOutletContext<ProductTemplateContext>();
}

function ProductTemplateOutlet({
    context,
}: {
    context: ProductTemplateContext;
}) {
    return <Outlet context={context} />;
}

interface ProductTemplateContext {
    showDtaAccount: boolean;
}

function AppStatusIconWithHint({
    appPurchaseVisibility,
    validityStart,
}: {
    appPurchaseVisibility: AppPurchaseVisibility | null;
    validityStart: string | null;
}): JSX.Element {
    const localize = makeLocalizedText(useOperatorLanguage());
    return (
        <Tooltip
            title={localize(
                productTypeAppStatusText(appPurchaseVisibility, validityStart),
            )}
            placement="right"
        >
            <ProductTypeAppStatusIcon
                appPurchaseVisibility={appPurchaseVisibility}
            />
        </Tooltip>
    );
}

export function ProductTypeAppStatusIcon({
    appPurchaseVisibility,
}: {
    appPurchaseVisibility: AppPurchaseVisibility | null;
}): JSX.Element {
    switch (appPurchaseVisibility) {
        case AppPurchaseVisibility.VISIBLE:
            return <CheckCircleOutline color="success" />;
        case AppPurchaseVisibility.HIDDEN:
            return <Schedule color="warning" />;
        case null:
            return <RemoveCircleOutline color="disabled" />;
    }
}

export function productTypeAppStatusText(
    appPurchaseVisibility: AppPurchaseVisibility | null,
    validityStart: string | null,
) {
    const validityStartFormatted = validityStart
        ? Formatter.dayMonthYear(DateTime.fromISO(validityStart))
        : null;

    switch (appPurchaseVisibility) {
        case AppPurchaseVisibility.VISIBLE:
            return {
                de: 'Verfügbar',
                fr: 'Disponible',
                it: 'Disponibile',
                en: 'Available',
            };
        case AppPurchaseVisibility.HIDDEN:
            if (validityStartFormatted != null) {
                return {
                    de: `Noch nicht sichtbar (erster buchbarer Tag: ${validityStartFormatted})`,
                    fr: `Pas encore visible (premier jour réservable: ${validityStartFormatted})`,
                    it: `Non ancora visibile (primo giorno prenotabile: ${validityStartFormatted})`,
                    en: `Not yet visible (first bookable day: ${validityStartFormatted})`,
                };
            }
            return {
                de: 'Noch nicht sichtbar',
                fr: 'Pas encore visible',
                it: 'Non ancora visibile',
                en: 'Not yet visible',
            };
        case null:
            return {
                de: 'Nicht möglich',
                fr: 'Pas possible',
                it: 'Non possilbe',
                en: 'Not possible',
            };
    }
}

function OperatorProductTypeZoneNameShort({
    parkingNames,
    enforcedZoneNames,
}: {
    parkingNames: string[];
    enforcedZoneNames: string[];
}) {
    if (parkingNames.length == 0 && enforcedZoneNames.length == 0)
        return <Box>{EmDash}</Box>;
    const isParking = parkingNames.length > enforcedZoneNames.length;
    if (isParking) {
        if (parkingNames.length == 1) {
            return <Box>{parkingNames[0]}</Box>;
        }
        return (
            <Localized
                de={`${parkingNames.length} Parkings`}
                fr={`${parkingNames.length} parkings`}
                it={`${parkingNames.length} parcheggi`}
                en={`${parkingNames.length} parkings`}
            />
        );
    }
    if (enforcedZoneNames.length == 1) {
        return <Box>{enforcedZoneNames[0]}</Box>;
    }
    return (
        <Localized
            de={`${parkingNames.length} Zonen`}
            fr={`${parkingNames.length} zones`}
            it={`${parkingNames.length} zone`}
            en={`${parkingNames.length} zones`}
        />
    );
}
