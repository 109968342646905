import { Box } from '@mui/material';
import { ParkingPortalLayout } from './ParkingPortalLayout.tsx';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

export function ParkingPortalLayoutWithHeader({
    title,
    children,
    scrollable,
    backTo,
    padded,
}: {
    title: string | ReactNode;
    children?: ReactNode | ReactNode[];
    scrollable?: boolean;
    backTo?: string;
    padded?: boolean;
}) {
    return (
        <ParkingPortalLayout style={{ padding: 0 }}>
            <ParkingPortalScreenHeader label={title} backTo={backTo} />
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    position: 'relative',
                    overflowX: 'hidden',
                    overflowY: scrollable ? 'auto' : 'hidden',
                    padding: padded ? '24px' : undefined,
                }}
            >
                {children}
            </Box>
        </ParkingPortalLayout>
    );
}

function ParkingPortalScreenHeader({
    label,
    backTo,
}: {
    label: ReactNode;
    backTo?: string;
}) {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexShrink: 0,
                height: 54,
                paddingX: 3,
                backgroundColor: theme => theme.palette.primary.main,
            }}
        >
            <Box
                sx={{
                    fontSize: '22px',
                    fontWeight: 700,
                    color: theme => theme.palette.primary.contrastText,
                }}
            >
                {label}
            </Box>
            {backTo && (
                <Box
                    onClick={() => navigate(backTo)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: theme => theme.palette.primary.contrastText,
                    }}
                >
                    <Close sx={{ fontSize: 35 }} />
                </Box>
            )}
        </Box>
    );
}
