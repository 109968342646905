import { Button } from '@mui/material';

export function ParkingPortalButton({
    onClick,
    children,
    icon,
}: {
    onClick: () => void;
    children: JSX.Element;
    icon?: JSX.Element;
}) {
    return (
        <Button
            sx={theme => ({
                color: theme.palette.white.main,
                backgroundColor: theme.palette.primary.main,
                padding: theme.spacing(3),
                fontSize: '16px',
                justifyContent: 'space-between',
            })}
            onClick={onClick}
        >
            {children}
            {icon}
        </Button>
    );
}
