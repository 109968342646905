import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { KeyboardArrowRight, Tag } from '@mui/icons-material';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { GroupSegment } from '../shared-mui-components/GroupSegment.tsx';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

export function CloudConnectorCounterOverview({
    mobile,
}: {
    mobile?: boolean;
}) {
    if (mobile) {
        return (
            <ParkingPortalLayoutWithHeader
                title={<CountersOverviewTitle />}
                scrollable
                backTo={'..'}
            >
                <CloudConnectorCounterContent />
            </ParkingPortalLayoutWithHeader>
        );
    } else {
        return (
            <MainContent>
                <HeaderWithButtons
                    title={<CountersOverviewTitle />}
                    icon={Tag}
                />
                <CloudConnectorCounterContent />
            </MainContent>
        );
    }
}

type CloudConnectorCounterZoneInfo = {
    zoneId: number;
    zoneName: string;
    counters: CloudConnectorCounterInfo[];
};

export type CloudConnectorCounterInfo = {
    zoneBarrierGateAreaCounterId: number;
    counterName: string;
    count: number;
    capacity: number;
};

function CloudConnectorCounterContent() {
    const navigate = useNavigate();
    const [counterZoneInfoState, refetchCounterZoneInfoState] = useServerFetch<
        CloudConnectorCounterZoneInfo[],
        Record<string, never>
    >(
        () => ({
            url: `/ui-api/operator-account/counter`,
        }),
        {},
    );

    return (
        <OperatorAsyncLoadedSection
            requestState={counterZoneInfoState}
            render={counterZoneInfo => (
                <>
                    {counterZoneInfo.map(zone => (
                        <GroupSegment key={zone.zoneId} label={zone.zoneName}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        md: 'repeat(2, minmax(0, 1fr))',
                                        lg: 'repeat(3, minmax(0, 1fr))',
                                    },
                                    gap: '12px',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {zone.counters.map(counter => (
                                    <SelectableCounterBox
                                        key={
                                            counter.zoneBarrierGateAreaCounterId
                                        }
                                        leftContent={counter.counterName}
                                        rightContent={
                                            <CounterBox
                                                count={counter.count}
                                                maxCount={counter.capacity}
                                            />
                                        }
                                        onClick={() =>
                                            navigate(
                                                counter.zoneBarrierGateAreaCounterId.toString(),
                                            )
                                        }
                                    />
                                ))}
                            </Box>
                        </GroupSegment>
                    ))}
                    <CounterOverviewOutlet
                        context={{
                            counterInfo: counterZoneInfo,
                            refetchCounterInfo: refetchCounterZoneInfoState,
                        }}
                    />
                </>
            )}
        />
    );
}

function SelectableCounterBox({
    leftContent,
    rightContent,
    onClick,
}: {
    leftContent: ReactNode;
    rightContent: ReactNode;
    onClick?: () => void;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '12px',
                padding: '12px 18px',
                gap: '10px',
                height: '80px',
                backgroundColor: theme => theme.palette.common.white,
                color: theme => theme.palette.blue.main,
                fontWeight: 500,
                fontSize: '20px',
                cursor: onClick ? 'pointer' : undefined,
                overflow: 'hidden',
            }}
            onClick={onClick}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {leftContent}
            </Box>
            <Box>{rightContent}</Box>
            {onClick && <KeyboardArrowRight />}
        </Box>
    );
}

function CounterBox({ count, maxCount }: { count: number; maxCount: number }) {
    return (
        <Box
            sx={{
                color: theme => theme.palette.common.white,
                backgroundColor: theme => theme.palette.blue.main,
                padding: '1px 6px 0px',
                borderRadius: '6px',
                flexShrink: 0,
                whiteSpace: 'nowrap',
            }}
        >
            {count} / {maxCount}
        </Box>
    );
}

export function CountersOverviewTitle() {
    return (
        <Localized de="Zähler" fr="Compteurs" it="Contatori" en="Counters" />
    );
}

type CounterOverviewOutletContext = {
    counterInfo: CloudConnectorCounterZoneInfo[];
    refetchCounterInfo: () => void;
};

export function useCounterOverviewOutlet() {
    return useOutletContext<CounterOverviewOutletContext>();
}

function CounterOverviewOutlet({
    context,
}: {
    context: CounterOverviewOutletContext;
}) {
    return <Outlet context={context} />;
}
