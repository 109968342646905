import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import { isDefined } from 'dg-web-shared/lib/MaybeV2.ts';
import {
    IconButton40px,
    IconButton40pxType,
} from '../../ui/buttons/IconButton.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16.tsx';
import { download } from 'dg-web-shared/ui/icons/Icons16.tsx';
import * as ActivityStatementState from '../state/ActivityStatementState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { Translation } from 'dg-web-shared/lib/Text.ts';
import { FilterSlideIn } from './FilterSlideIn.tsx';
import * as ActivityStatementTexts from '../i18n/ActivityStatementTexts.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import moment from 'moment';
import { Body, Container } from '../../ui/layout/SingleContent.tsx';
import {
    ColAlign,
    ColumnWidth,
    DefaultTableRow,
    EmptyTableHeaderColumn,
    SortDirection,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from '../../ui/table/Table.tsx';
import { NoPermission } from '../../common/components/NoPermission.tsx';
import * as Http from '../../api/Http.ts';
import { BaseAbstractLegacyServerState } from 'dg-web-shared/lib/AbstractLegacyServerStateSlice.ts';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { IconTopOffsetContainer } from '../../zones/components/ZoneListBody.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { Icon16 } from '../../ui/icons/Icon.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Localized } from '../../common/components/Localized.tsx';

type ActivityMonth = ActivityStatementState.Data.ActivityMonth;

interface State {
    settings: SettingsState.State;
    layout: ActivityStatementState.Layout.State;
    filter: ActivityStatementState.Filter.State;
    data: ActivityStatementState.Data.State;
    currentLogin: CurrentOperatorLoginState.State;
}

export interface Texts {
    Total: Translation;
    MonthCol: Translation;
    TransactionsCol: Translation;
    PermitsCol: Translation;
    ProductsCol: Translation;
    RefundsCol: Translation;
    InkassoCol: Translation;
    VersionCol: Translation;
    OthersCol: Translation;
    TotalCol: Translation;
    PdfCol: Translation;
    ExportCol: Translation;
    CurrentMonth: Translation;
}

export function ActivityTableHeader({
    auditReportColumn,
    includesParkingAboProducts,
    showRecodeColumn,
    settings,
}: {
    auditReportColumn: ActivityStatementState.Data.AuditReportType;
    includesParkingAboProducts: boolean;
    showRecodeColumn: boolean;
    settings: SettingsState.State;
}) {
    const txt = ActivityStatementTexts.texts[settings.language];
    return (
        <TableHeader>
            <EmptyTableHeaderColumn width={ColumnWidth._24px} />
            <TableHeaderColumn
                selected={true}
                direction={SortDirection.Descending}
                width={ColumnWidth.variable}
                name={txt.MonthCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.TransactionsCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.PermitsCol()}
            />
            <Conditional c={includesParkingAboProducts}>
                <TableHeaderColumn
                    align={ColAlign.right}
                    width={ColumnWidth._108px}
                    name={txt.ProductsCol()}
                />
            </Conditional>
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.RefundsCol()}
            />
            <Conditional c={showRecodeColumn}>
                <TableHeaderColumn
                    align={ColAlign.right}
                    width={ColumnWidth._108px}
                    name={
                        <Localized
                            de="Rabatte"
                            fr="Réductions"
                            it="Sconti"
                            en="Discounts"
                        />
                    }
                />
            </Conditional>
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.InkassoCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.VersionCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.OthersCol()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._108px}
                name={txt.Total()}
            />
            <TableHeaderColumn
                align={ColAlign.right}
                width={ColumnWidth._72px}
                name={txt.PdfCol()}
            />
            <Conditional c={auditReportColumn !== 'NONE'}>
                <TableHeaderColumn
                    align={ColAlign.right}
                    width={ColumnWidth._72px}
                    name={txt.ExportCol()}
                />
            </Conditional>
        </TableHeader>
    );
}

export interface MonthSum {
    transactions: number;
    permits: number;
    parkingAboProducts: number;
    refundCount: number;
    comission: number;
    other: number;
    calculatedBalance: number;
    recodeCount: number;
}

const monthSum = (months: ActivityMonth[]): MonthSum => {
    const acc: MonthSum = {
        transactions: 0,
        permits: 0,
        parkingAboProducts: 0,
        refundCount: 0,
        comission: 0,
        other: 0,
        calculatedBalance: 0,
        recodeCount: 0,
    };
    const FLOAT_DIV = 1000;
    for (const m of months) {
        acc.transactions += m.transactions * FLOAT_DIV;
        acc.permits += m.permits * FLOAT_DIV;
        acc.parkingAboProducts += m.parkingAboProducts * FLOAT_DIV;
        acc.comission += m.comission * FLOAT_DIV;
        acc.other += m.other * FLOAT_DIV;
        acc.calculatedBalance += m.calculatedBalance * FLOAT_DIV;
        acc.refundCount += m.refundCount;
        acc.recodeCount += m.recodeCount;
    }
    acc.transactions /= FLOAT_DIV;
    acc.permits /= FLOAT_DIV;
    acc.parkingAboProducts /= FLOAT_DIV;
    acc.comission /= FLOAT_DIV;
    acc.other /= FLOAT_DIV;
    acc.calculatedBalance /= FLOAT_DIV;
    return acc;
};

const currentMonth = (month: moment.Moment) =>
    month.month() === moment().month() && month.year() === moment().year();

const getPeriodString = (month: moment.Moment, lang: string) => {
    if (currentMonth(month)) {
        return ActivityStatementTexts.texts[lang].CurrentMonth();
    }
    return Formatter.monthAbrevYear(month, Formatter.getLocaleFromString(lang));
};

export function Row({
    month,
    language,
    includesParkingAboProducts,
    showRecodeColumn,
}: {
    month: ActivityMonth;
    language: string;
    includesParkingAboProducts: boolean;
    showRecodeColumn: boolean;
}): JSX.Element {
    return (
        <DefaultTableRow key={month.period.toISOString()} hoverable={false}>
            <TableColumn width={ColumnWidth._24px} />
            <TableColumn width={ColumnWidth.variable} captionFontSize={true}>
                {getPeriodString(month.period, language)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {NumberFormatter.numberToPrice(month.transactions, false)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {NumberFormatter.numberToPrice(month.permits, false)}
            </TableColumn>
            <Conditional c={includesParkingAboProducts}>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    {NumberFormatter.numberToPrice(
                        month.parkingAboProducts,
                        false,
                    )}
                </TableColumn>
            </Conditional>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {!currentMonth(month.period) && !!month.refundCount ? (
                    <>
                        {month.refundCount}
                        <DownloadLink
                            href={`/ui-api/operator-account/refund-export/${month.period.format(
                                'YYYY-MM',
                            )}?language=${language}`}
                        />
                    </>
                ) : (
                    EmDash
                )}
            </TableColumn>
            <Conditional c={showRecodeColumn}>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    {!currentMonth(month.period) && !!month.recodeCount ? (
                        <>
                            {month.recodeCount}
                            <DownloadLink
                                href={`/ui-api/operator-account/recode-export/${month.period.format(
                                    'YYYY-MM',
                                )}?language=${language}`}
                            />
                        </>
                    ) : (
                        EmDash
                    )}
                </TableColumn>
            </Conditional>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {currentMonth(month.period)
                    ? EmDash
                    : NumberFormatter.numberToPrice(month.comission, false)}
                {!currentMonth(month.period) && (
                    <DownloadLink
                        href={`/ui-api/operator-account/commission-export/${month.period.format(
                            'YYYY-MM',
                        )}?language=${language}`}
                    />
                )}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {month.contractVersion == null
                    ? EmDash
                    : month.contractVersion.toUpperCase()}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                {NumberFormatter.numberToPrice(month.other, false)}
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                <b>
                    {NumberFormatter.numberToPrice(
                        month.calculatedBalance,
                        false,
                    )}
                </b>
            </TableColumn>
            <TableColumn align={ColAlign.right} width={ColumnWidth._72px}>
                <Conditional c={month.hasEndOfMonthReport}>
                    <a
                        target="_blank"
                        className={css({
                            textDecoration: 'none',
                            color: ColorHex.darkblue,
                        })}
                        href={
                            month.period.isBefore(moment('2017-12-01'))
                                ? Http.OperatorAccount.ActivityStatement.pdfUrl(
                                      Formatter.isoYearMonth(month.period),
                                  )
                                : Http.OperatorAccount.ActivityStatement.newPdfUrl(
                                      month.period.year(),
                                      month.period.month() + 1,
                                  )
                        }
                        rel="noreferrer"
                    >
                        <IconTopOffsetContainer>
                            <Icon16 icon={Icons16.pdf} />
                        </IconTopOffsetContainer>
                    </a>
                </Conditional>
            </TableColumn>
            <Conditional c={month.specialReport !== 'NONE'}>
                <TableColumn align={ColAlign.right} width={ColumnWidth._72px}>
                    <a
                        target="_blank"
                        className="AcitvityStatementPdfLink"
                        href={Http.OperatorAccount.ActivityStatement.auditReport(
                            month.specialReport,
                            month.period.year(),
                            month.period.month() + 1,
                        )}
                        rel="noreferrer"
                    >
                        <Icon16 icon={Icons16.download} />
                    </a>
                </TableColumn>
            </Conditional>
        </DefaultTableRow>
    );
}

export function ActivityTableBody({
    auditReportColumn,
    includesParkingAboProducts,
    showRecodeColumn,
    settings,
    filter,
    activityMonthsData,
}: {
    auditReportColumn: ActivityStatementState.Data.AuditReportType;
    includesParkingAboProducts: boolean;
    showRecodeColumn: boolean;
    settings: SettingsState.State;
    filter: ActivityStatementState.Filter.State;
    activityMonthsData: ActivityStatementState.Data.ActivityMonth[];
}) {
    const data = activityMonthsData.filter((m): boolean => {
        const { fromFilter, toFilter } = filter;
        if (isDefined(fromFilter) && m.period.isBefore(fromFilter)) {
            return false;
        }
        if (isDefined(toFilter) && m.period.isAfter(toFilter)) {
            return false;
        }
        return true;
    });
    const sum = monthSum(data);
    return (
        <TableBody>
            <TableRow
                className={css({
                    borderTop: `1px solid ${ColorHex.rgba(
                        ColorHex.lightblue,
                        0.1,
                    )}`,
                })}
                key={'sum'}
            >
                <TableColumn width={ColumnWidth._24px} />
                <TableColumn
                    width={ColumnWidth.variable}
                    captionFontSize={true}
                >
                    <b>
                        {ActivityStatementTexts.texts[
                            settings.language
                        ].Total()}
                    </b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>
                        {NumberFormatter.numberToPrice(sum.transactions, false)}
                    </b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>{NumberFormatter.numberToPrice(sum.permits, false)}</b>
                </TableColumn>
                <Conditional c={includesParkingAboProducts}>
                    <TableColumn
                        align={ColAlign.right}
                        width={ColumnWidth._108px}
                    >
                        <b>
                            {NumberFormatter.numberToPrice(
                                sum.parkingAboProducts,
                                false,
                            )}
                        </b>
                    </TableColumn>
                </Conditional>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>{sum.refundCount || EmDash}</b>
                </TableColumn>
                <Conditional c={showRecodeColumn}>
                    <TableColumn
                        align={ColAlign.right}
                        width={ColumnWidth._108px}
                    >
                        <b>{sum.recodeCount || EmDash}</b>
                    </TableColumn>
                </Conditional>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>{NumberFormatter.numberToPrice(sum.comission, false)}</b>
                </TableColumn>
                <TableColumn
                    align={ColAlign.right}
                    width={ColumnWidth._108px}
                />
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>{NumberFormatter.numberToPrice(sum.other, false)}</b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={ColumnWidth._108px}>
                    <b>
                        {NumberFormatter.numberToPrice(
                            sum.calculatedBalance,
                            false,
                        )}
                    </b>
                </TableColumn>
                <TableColumn align={ColAlign.right} width={ColumnWidth._72px} />
                <Conditional c={auditReportColumn !== 'NONE'}>
                    <TableColumn
                        align={ColAlign.right}
                        width={ColumnWidth._72px}
                    />
                </Conditional>
            </TableRow>
            {data.map((m, i) => (
                <Row
                    month={m}
                    language={settings.language}
                    key={`r${i}${m}`}
                    includesParkingAboProducts={includesParkingAboProducts}
                    showRecodeColumn={showRecodeColumn}
                />
            ))}
        </TableBody>
    );
}

function DownloadLink(props: { href: string }) {
    return (
        <a
            target="_blank"
            className={css({
                display: 'inline-block',
                verticalAlign: 'bottom',
                textDecoration: 'none',
                marginLeft: '4px',
                marginBottom: '-3px',
                color: ColorHex.darkblue,
            })}
            href={props.href}
            rel="noreferrer"
        >
            <Icon16 icon={download} />
        </a>
    );
}

export class ActivityStatement extends Flux.Container<State> {
    stateSelector(): State {
        return {
            settings: new SettingsState.StateSlice(this.props.allState).state,
            layout: ActivityStatementState.Layout.get(this.props.allState),
            filter: ActivityStatementState.Filter.get(this.props.allState),
            data: ActivityStatementState.Data.get(this.props.allState),
            currentLogin: CurrentOperatorLoginState.get(this.props.allState),
        };
    }

    renderHeader(): JSX.Element {
        const locale = Formatter.getLocaleFromString(
            this.state.settings.language,
        );
        return (
            <div
                className={css({
                    position: 'absolute',
                    top: '0px',
                    width: '100%',
                    height: '60px',
                    paddingLeft: '24px',
                })}
            >
                <div
                    className={css({
                        float: 'left',
                        marginTop: '8px',
                        marginRight: '16px',
                    })}
                >
                    <IconButton40px
                        onClick={() =>
                            this.update(store =>
                                ActivityStatementState.Layout.stateWrite(
                                    store,
                                    { filterOpen: true },
                                ),
                            )
                        }
                        type={IconButton40pxType.datePicker}
                    />
                </div>
                <div
                    className={css({
                        marginTop: '20px',
                        ...Typo.captionC3,
                        color: ColorHex.darkblue,
                        float: 'left',
                    })}
                >
                    {Formatter.monthAbrevYear(
                        this.state.filter.fromFilter,
                        locale,
                    ) +
                        ' - ' +
                        Formatter.monthAbrevYear(
                            this.state.filter.toFilter,
                            locale,
                        )}
                </div>
            </div>
        );
    }

    getDependingStateSlices(): BaseAbstractLegacyServerState[] {
        return [this.state.data];
    }

    render() {
        if (
            isDefined(this.state.currentLogin.data) &&
            !this.state.currentLogin.data.permissions.activityStatement
        ) {
            return <NoPermission />;
        }
        const specialReportColumn =
            this.state.data.data.length > 0
                ? this.state.data.data[0].specialReport
                : 'NONE';
        const includesParkingAboProducts =
            this.state.data.data.reduce(
                (sum, activityMonth) => sum + activityMonth.parkingAboProducts,
                0,
            ) > 0.0;
        const showRecodeColumn = this.state.data.data.some(
            activityMonth => activityMonth.recodeCount > 0,
        );
        return (
            <Container>
                <Body
                    disabled={this.state.layout.filterOpen || false}
                    slideIns={<FilterSlideIn allState={this.props.allState} />}
                    dependingStateSlices={this.getDependingStateSlices()}
                >
                    {this.renderHeader()}
                    <div
                        className={css({
                            position: 'absolute',
                            top: '60px',
                            bottom: 0,
                            width: '100%',
                        })}
                    >
                        <Table>
                            <ActivityTableHeader
                                auditReportColumn={specialReportColumn}
                                includesParkingAboProducts={
                                    includesParkingAboProducts
                                }
                                showRecodeColumn={showRecodeColumn}
                                settings={this.state.settings}
                            />
                            <ActivityTableBody
                                auditReportColumn={specialReportColumn}
                                includesParkingAboProducts={
                                    includesParkingAboProducts
                                }
                                showRecodeColumn={showRecodeColumn}
                                settings={this.state.settings}
                                filter={this.state.filter}
                                activityMonthsData={this.state.data.data}
                            />
                        </Table>
                    </div>
                </Body>
            </Container>
        );
    }
}
