import { ReactNode } from 'react';
import { Box } from '@mui/material';

export function GroupSegment({
    label,
    children,
}: {
    label: ReactNode;
    children: ReactNode;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px 24px 0px  24px',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    alignContent: 'center',
                    height: '30px',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: theme => theme.palette.white.main,
                    backgroundColor: theme => theme.palette.primary.main,
                }}
            >
                {label}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    padding: '24px',
                    backgroundColor: theme => theme.palette.blue.light,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
