import { generatePath, Navigate, useNavigate } from 'react-router-dom';
import { Localized } from '../common/components/Localized.tsx';
import { Box } from '@mui/material';
import { ParkingPortalMenuListItem } from '../mobile/layout/ParkingPortalMenuListItem.tsx';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { useRecodeTicketOutlet } from './CloudConnectorRecodeTicketConditionsOutlet.tsx';

export function CloudConnectorRecodeTicket() {
    const { recodeConditions } = useRecodeTicketOutlet();
    const navigate = useNavigate();
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Rabattbedingungen"
                    fr="Conditions de rabais"
                    it="Condizioni ribasso"
                    en="Discount conditions"
                />
            }
            backTo={'..'}
        >
            <Box>
                {recodeConditions.length == 1 ? (
                    <Navigate
                        to={generatePath(
                            'recodeCondition/:recodeConditionId',
                            recodeConditions[0],
                        )}
                    />
                ) : (
                    recodeConditions.map(recodeCondition => (
                        <ParkingPortalMenuListItem
                            key={recodeCondition.recodeConditionId}
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        'recodeCondition/:recodeConditionId',
                                        recodeCondition,
                                    ),
                                )
                            }
                            text={
                                <Localized
                                    {...recodeCondition.recodeConditionDescription}
                                />
                            }
                        />
                    ))
                )}
            </Box>
        </ParkingPortalLayoutWithHeader>
    );
}
