import { Localized } from '../../admin-app/src/common/Localized';

export enum ContractTemplateOperatorState {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export const operatorStateOptions = [
    {
        text: (
            <OperatorStateText
                operatorState={ContractTemplateOperatorState.DRAFT}
            />
        ),
        value: ContractTemplateOperatorState.DRAFT,
    },
    {
        text: (
            <OperatorStateText
                operatorState={ContractTemplateOperatorState.ACTIVE}
            />
        ),
        value: ContractTemplateOperatorState.ACTIVE,
    },
    {
        text: (
            <OperatorStateText
                operatorState={ContractTemplateOperatorState.INACTIVE}
            />
        ),
        value: ContractTemplateOperatorState.INACTIVE,
    },
];

export function OperatorStateText({
    operatorState,
}: {
    operatorState: ContractTemplateOperatorState;
}) {
    switch (operatorState) {
        case ContractTemplateOperatorState.DRAFT:
            return (
                <Localized de="Entwurf" fr="Brouillon" it="Bozza" en="Draft" />
            );
        case ContractTemplateOperatorState.ACTIVE:
            return <Localized de="Aktiv" fr="Actif" it="Attivo" en="Active" />;
        case ContractTemplateOperatorState.INACTIVE:
            return (
                <Localized
                    de="Inaktiv"
                    fr="Inactif"
                    it="Inattivo"
                    en="Inactive"
                />
            );
    }
}

export function getOperatorStateColor(
    operatorState: ContractTemplateOperatorState,
) {
    switch (operatorState) {
        case ContractTemplateOperatorState.DRAFT:
            return '#FFF4E4';
        case ContractTemplateOperatorState.INACTIVE:
            return '#FFE7E7';
    }
}
