import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    Check,
    CloudDownload,
    HourglassTop,
    Lock,
    People,
    PersonAdd,
    Remove,
} from '@mui/icons-material';
import {
    generatePath,
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { Localized } from '../common/components/Localized.tsx';
import {
    OperatorParkingaboUserCreateForm,
    OperatorParkingaboUserInviteForm,
    ParkingaboUser,
    Tenant,
} from './OperatorParkingaboUsersForm.tsx';
import {
    RequestMethod,
    RequestStatus,
    ServerRequestState,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import {
    FilterConfig,
    makeSearchQueryWithFilters,
} from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { getCustomerDisplayName } from 'dg-web-shared/common/models/Users.tsx';
import { MainContent } from '../shared-mui-components/MainContent.tsx';
import {
    EmptyResults,
    FetchError,
    ResultLimitAlert,
} from '../shared-mui-components/ListMessages.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { FiltersBox } from '../shared-mui-components/filter/FiltersBox.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { Language } from 'dg-web-shared/lib/Localized.ts';
import {
    ButtonWithDropdown,
    DropdownItem,
} from '../layout/components/ButtonWithDropdown.tsx';
import { useMemo } from 'react';

import {
    AppMode,
    TenantAllowedBarrierGateVehicleIdentification,
} from 'dg-web-shared/model/TenantEnums.ts';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { CustomerTenantState } from 'dg-web-shared/model/CustomerTenantState.ts';

export interface CustomerSearchResult {
    eof: boolean;
    searchTerms: string[];
    results: ParkingaboCustomer[];
}

interface ParkingaboCustomer {
    customerId: number;
    tenantId: number;
    customerNr: string;
    firstName: string | null;
    lastName: string | null;
    language: Language;
    reference: string | null;
    onboardingCompleteTime: string | null;
    state: CustomerTenantState;
    email: string | null;
}

function HeaderButtons({
    tenants,
    listState,
    searchText,
    activeFilters,
}: {
    tenants: Tenant[];
    listState: ServerRequestState<CustomerSearchResult, CustomerSearchResult>;
    searchText: string | null;
    activeFilters: FilterConfig;
}) {
    const language = useOperatorLanguage();
    const { search } = useLocation();
    const paramsWithLanguage = useMemo(() => {
        const params = new URLSearchParams(search);
        params.append('language', language);
        return params;
    }, [search, language]);
    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            {listState.status === RequestStatus.SUCCESS &&
                listState.data.results.length > 0 && (
                    <Button
                        variant="outlined"
                        startIcon={<CloudDownload />}
                        href={`/ui-api/operator-account/parkingabo/customer/export?${paramsWithLanguage.toString()}`}
                    >
                        <Localized
                            de="Exportieren"
                            fr="Exporter"
                            it="Esporta"
                            en="Export"
                        />
                    </Button>
                )}
            <CreateParkingaboUserButton
                tenants={tenants}
                searchText={searchText}
                activeFilters={activeFilters}
            />
        </Stack>
    );
}

function getTenantsDropdownOptions(
    tenants: Tenant[],
    onClick: (tenantId: string) => void,
): DropdownItem[] {
    return tenants.map(tenant => ({
        key: tenant.tenantId,
        label: <Localized {...tenant.name} />,
        disabled: false,
        onClickCallback: () => onClick(tenant.tenantId),
    }));
}

function getInviteParkingaboUserUrl(
    selectedTenantId: string,
    searchText: string | null,
    activeFilters: FilterConfig,
) {
    return `/parkingabo/users/invite/tenant/${selectedTenantId}/${makeSearchQueryWithFilters(
        searchText,
        activeFilters,
    )}`;
}

function getCreateParkingaboUserUrl(
    selectedTenantId: string,
    searchText: string | null,
    activeFilters: FilterConfig,
) {
    return `/parkingabo/users/add/tenant/${selectedTenantId}/${makeSearchQueryWithFilters(
        searchText,
        activeFilters,
    )}`;
}

function CreateParkingaboUserButton({
    tenants,
    searchText,
    activeFilters,
}: {
    tenants: Tenant[];
    searchText: string | null;
    activeFilters: FilterConfig;
}) {
    const navigate = useNavigate();
    const tenantsWithCreateUserFunction = tenants.filter(
        tenant => tenant.appMode === AppMode.NONE,
    );
    const tenantsWithInviteUserFunction = tenants.filter(
        tenant =>
            tenant.appMode === AppMode.NO_SIGNUP &&
            (tenant.allowedBarrierGateVehicleIdentification ==
                TenantAllowedBarrierGateVehicleIdentification.BADGE ||
                tenant.allowedBarrierGateVehicleIdentification ==
                    TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE),
    );

    if (
        tenantsWithCreateUserFunction.length === 0 &&
        tenantsWithInviteUserFunction.length === 0
    ) {
        return null;
    }

    const tenantWithInviteOptions = getTenantsDropdownOptions(
        tenantsWithInviteUserFunction,
        tenantId => {
            navigate(
                getInviteParkingaboUserUrl(tenantId, searchText, activeFilters),
            );
        },
    );
    const tenantWithCreateOptions = getTenantsDropdownOptions(
        tenantsWithCreateUserFunction,
        tenantId => {
            navigate(
                getCreateParkingaboUserUrl(tenantId, searchText, activeFilters),
            );
        },
    );

    const divider: DropdownItem = { key: 'divider', divider: true };

    const hasOnlyOneTenantOption =
        tenantsWithInviteUserFunction.length +
            tenantsWithCreateUserFunction.length ===
        1;
    if (hasOnlyOneTenantOption) {
        return (
            <Link
                to={
                    tenantsWithCreateUserFunction.length > 0
                        ? getCreateParkingaboUserUrl(
                              tenantsWithCreateUserFunction[0].tenantId,
                              searchText,
                              activeFilters,
                          )
                        : getInviteParkingaboUserUrl(
                              tenantsWithInviteUserFunction[0].tenantId,
                              searchText,
                              activeFilters,
                          )
                }
            >
                <Button
                    variant="contained"
                    startIcon={<PersonAdd />}
                    color="primary"
                >
                    <Localized
                        de="Neues Konto"
                        fr="Nouveaux compte"
                        it="Nuovo conto"
                        en="New account"
                    />
                </Button>
            </Link>
        );
    }

    const hasTenantsForBothOptions =
        tenantWithCreateOptions.length > 0 &&
        tenantWithInviteOptions.length > 0;
    return (
        <ButtonWithDropdown
            label={
                <Localized
                    de="Neues Konto"
                    fr="Nouveaux compte"
                    it="Nuovo conto"
                    en="New account"
                />
            }
            startIcon={<PersonAdd />}
            dropdownItems={[
                ...(hasTenantsForBothOptions
                    ? [
                          {
                              key: 'create',
                              label: (
                                  <Localized
                                      de="ERFASSEN"
                                      fr="SAISIR"
                                      it="REGISTRA"
                                      en="CAPTURE"
                                  />
                              ),
                              disabled: true,
                              onClickCallback: () => null,
                          },
                      ]
                    : []),
                ...tenantWithCreateOptions,

                ...(hasTenantsForBothOptions
                    ? [
                          divider,
                          {
                              key: 'invite',
                              label: (
                                  <Localized
                                      de="EINLADEN"
                                      fr="INVITER"
                                      it="INVITA"
                                      en="INVITE"
                                  />
                              ),
                              disabled: true,
                              onClickCallback: () => null,
                          },
                      ]
                    : []),
                ...tenantWithInviteOptions,
            ]}
        />
    );
}

export function OperatorParkingaboUsers() {
    const navigate = useNavigate();

    const {
        filterConfig,
        activeFilters,
        listState,
        refetchList,
        searchText,
        filterConfigState,
    } = useParkingaboUserFilterConfiguration();

    const [tenantsState] = useServerFetch<Tenant[], object>(
        () => ({
            url: `/ui-api/operator-account/parkingabo/tenants`,
        }),
        {},
    );
    const [customerLifecycleState, setCustomerLifecycle] = useServerWrite<
        { state: CustomerTenantState; customerNr: string },
        ParkingaboUser
    >(({ customerNr }) => ({
        url: `/ui-api/operator-account/parkingabo/customer/${customerNr}/lifecycle`,
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(customerLifecycleState, refetchList);

    return (
        <OperatorAsyncLoadedSection
            requestState={tenantsState}
            render={tenants => {
                return (
                    <MainContent>
                        <HeaderWithButtons
                            title={
                                <Localized
                                    de="Benutzerkonti"
                                    fr="Comptes utilisateur"
                                    it="Conti utente"
                                    en="User accounts"
                                />
                            }
                            icon={People}
                            headerButtons={
                                <HeaderButtons
                                    tenants={tenants}
                                    listState={listState}
                                    searchText={searchText}
                                    activeFilters={activeFilters}
                                />
                            }
                        />
                        <FiltersBox
                            searchText={searchText}
                            activeFilters={activeFilters}
                            filterConfig={filterConfig}
                            requestState={listState}
                            refetch={refetchList}
                            pending={
                                filterConfigState.status ===
                                RequestStatus.PENDING
                            }
                        />
                        <OperatorParkingaboUserTable
                            listState={listState}
                            tenants={tenants}
                            refetchList={refetchList}
                            onRowClick={(customerNr: string) =>
                                navigate(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/detail',
                                        { customerNr: customerNr },
                                    )}${makeSearchQueryWithFilters(
                                        searchText,
                                        activeFilters,
                                    )}`,
                                )
                            }
                        />

                        <ParkingaboUserOutlet
                            context={{
                                tenants: tenants,
                                customerLifecycleState: customerLifecycleState,
                                setCustomerLifecycle: setCustomerLifecycle,
                            }}
                        />
                    </MainContent>
                );
            }}
        />
    );
}

export function useParkingaboUserOutletContext() {
    return useOutletContext<ParkingaboUserContext>();
}

function ParkingaboUserOutlet({ context }: { context: ParkingaboUserContext }) {
    return <Outlet context={context} />;
}

export interface ParkingaboUserContext {
    tenants: Tenant[];
    customerLifecycleState: ServerRequestState<ParkingaboUser, object>;
    setCustomerLifecycle: (state: {
        state: CustomerTenantState;
        customerNr: string;
    }) => void;
}

export function OperatorParkingaboAddUser() {
    const { tenants } = useParkingaboUserOutletContext();
    const { activeFilters, refetchList, searchText } =
        useParkingaboUserFilterConfiguration();
    const urlParams = useParams<{ tenantId: string }>();

    const tenantOfUser = getTenantforCustomer(
        tenants,
        Number(urlParams.tenantId),
    );

    if (!tenantOfUser) {
        return null;
    }

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={`/parkingabo/users${makeSearchQueryWithFilters(
                searchText,
                activeFilters,
            )}`}
            render={controller => (
                <OperatorParkingaboUserCreateForm
                    onCreated={newCustomerNr => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchList();
                        controller.safeNavigateTo(
                            generatePath(
                                '/parkingabo/users/:customerNr/detail',
                                {
                                    customerNr: newCustomerNr,
                                },
                            ),
                        );
                    }}
                    tenantOfUser={tenantOfUser}
                    tenants={tenants}
                    onClose={controller.close}
                    onDirtyStateChange={controller.setShouldConfirmBeforeLeave}
                />
            )}
        />
    );
}

export function OperatorParkingaboInviteUser() {
    const { tenants } = useParkingaboUserOutletContext();
    const { activeFilters, refetchList, searchText } =
        useParkingaboUserFilterConfiguration();
    const urlParams = useParams<{ tenantId: string }>();

    const tenantOfUser = getTenantforCustomer(
        tenants,
        Number(urlParams.tenantId),
    );

    if (!tenantOfUser) {
        return null;
    }

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={`/parkingabo/users${makeSearchQueryWithFilters(
                searchText,
                activeFilters,
            )}`}
            render={controller => (
                <OperatorParkingaboUserInviteForm
                    onCreated={newCustomerNr => {
                        controller.setShouldConfirmBeforeLeave(false);
                        refetchList();
                        controller.safeNavigateTo(
                            generatePath(
                                '/parkingabo/users/:customerNr/detail',
                                {
                                    customerNr: newCustomerNr,
                                },
                            ),
                        );
                    }}
                    tenantOfUser={tenantOfUser}
                    tenants={tenants}
                    onClose={controller.close}
                    onDirtyStateChange={controller.setShouldConfirmBeforeLeave}
                />
            )}
        />
    );
}

function getTenantforCustomer(
    tenants: Tenant[],
    tenantIdOfCustomer: number,
): Tenant | null {
    return (
        tenants.find(
            tenant => Number(tenant.tenantId) === tenantIdOfCustomer,
        ) ?? null
    );
}

function OperatorParkingaboUserTable({
    listState,
    tenants,
    refetchList,
    onRowClick,
}: {
    listState: ServerRequestState<CustomerSearchResult, CustomerSearchResult>;
    refetchList: () => void;
    tenants: Tenant[];
    onRowClick: (customerNr: string) => void;
}) {
    const operatorHasMultipleTenants = tenants.length > 1;

    return (
        <OperatorAsyncLoadedSection
            requestState={listState}
            pendingLoaderPreset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            render={results => {
                const isEmpty = results.results.length === 0;

                if (isEmpty) {
                    return <EmptyResults />;
                }

                return (
                    <>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Status"
                                                fr="Status"
                                                it="Status"
                                                en="Status"
                                            />
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                paddingLeft: 3.25,
                                            }}
                                        >
                                            <Localized
                                                de="Name"
                                                fr="Nom"
                                                it="Nome"
                                                en="Name"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Referenz"
                                                fr="Référence"
                                                it="Referenza"
                                                en="Reference"
                                            />
                                        </TableCell>
                                        {operatorHasMultipleTenants && (
                                            <TableCell
                                                sx={{
                                                    paddingRight: 3.25,
                                                }}
                                            >
                                                <Localized
                                                    de="Betriebsart"
                                                    fr="Modalité de gestion"
                                                    it="Modalità di gestione"
                                                    en="Operating mode"
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Localized
                                                de="ID"
                                                fr="ID"
                                                it="ID"
                                                en="ID"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.results.map(customer => {
                                        const tenantOfUser =
                                            getTenantforCustomer(
                                                tenants,
                                                customer.tenantId,
                                            );

                                        if (tenantOfUser === null) {
                                            return null;
                                        }

                                        return (
                                            <TableRow
                                                key={customer.customerNr}
                                                hover
                                                onClick={() =>
                                                    onRowClick(
                                                        customer.customerNr,
                                                    )
                                                }
                                            >
                                                <TableCell>
                                                    <IconForCustomerStatus
                                                        status={customer.state}
                                                        onboardingCompleteTime={
                                                            customer.onboardingCompleteTime
                                                        }
                                                        appMode={
                                                            tenantOfUser.appMode
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        paddingLeft: 3.25,
                                                    }}
                                                >
                                                    {getCustomerDisplayName(
                                                        customer,
                                                        true,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {customer.reference || '-'}
                                                </TableCell>
                                                {operatorHasMultipleTenants && (
                                                    <TableCell
                                                        sx={{
                                                            paddingRight: 3.25,
                                                        }}
                                                    >
                                                        {tenantOfUser && (
                                                            <Localized
                                                                {...tenantOfUser.name}
                                                            />
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    {customer.customerNr}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <ResultLimitAlert
                                eof={results.eof}
                                numberOfResults={results.results.length}
                                hasExport={true}
                            />
                        </TableContainer>
                    </>
                );
            }}
            renderError={() => <FetchError refetchList={refetchList} />}
        />
    );
}

export function IconForCustomerStatus({
    status,
    onboardingCompleteTime,
    appMode,
}: {
    status: CustomerTenantState;
    onboardingCompleteTime: string | null;
    appMode: AppMode;
}) {
    switch (status) {
        case CustomerTenantState.ACTIVE:
            if (onboardingCompleteTime || appMode === AppMode.NONE) {
                return <Check sx={{ color: 'success.main' }} />;
            }
            return <HourglassTop sx={{ color: 'warning.main' }} />;
        case CustomerTenantState.LOCKED:
            return <Lock sx={{ color: 'warning.main' }} />;
        case CustomerTenantState.ARCHIVED:
            return <Remove sx={{ color: 'error.main' }} />;
    }
}
