import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import {
    CloudConnectorCounterInfo,
    useCounterOverviewOutlet,
} from './CloudConnectorCounterOverview.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { Localized } from '../common/components/Localized.tsx';
import {
    RequestStatus,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { MobileSectionTitle } from '../shared-mui-components/MobileSectionTitle.tsx';
import Alert from '@mui/material/Alert';
import { Box, Button } from '@mui/material';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { UncontrolledMobileNumericInput } from '../shared-mui-components/UncontrolledMobileNumericInput.tsx';

type CloudConnectorCounterDetail = CloudConnectorCounterInfo & {
    zoneName: string;
};

export function CloudConnectorCounterDetail({ mobile }: { mobile?: boolean }) {
    const { zoneBarrierGateAreaCounterId } = useParams<{
        zoneBarrierGateAreaCounterId?: string;
    }>();

    const { counterInfo, refetchCounterInfo } = useCounterOverviewOutlet();

    const counter = useMemo<CloudConnectorCounterDetail | null>(() => {
        if (!zoneBarrierGateAreaCounterId) {
            return null;
        }
        const parsedZoneBarrierGateAreaCounterId = parseInt(
            zoneBarrierGateAreaCounterId,
            10,
        );
        return (
            counterInfo.flatMap(zoneGroup =>
                zoneGroup.counters
                    .filter(
                        counter =>
                            counter.zoneBarrierGateAreaCounterId ===
                            parsedZoneBarrierGateAreaCounterId,
                    )
                    .map(counter => ({
                        ...counter,
                        zoneName: zoneGroup.zoneName,
                    })),
            )[0] ?? null
        );
    }, [zoneBarrierGateAreaCounterId, counterInfo]);

    return (
        <OperatorRoutedModal
            variant={mobile ? ModalVariant.MOBILE : ModalVariant.SMALL}
            backUrl={'..'}
            render={() => (
                <ParkingPortalLayoutWithHeader
                    title={
                        <>
                            <Localized
                                de="Zähler"
                                fr="Compteur"
                                it="Contatore"
                                en="Counter"
                            />{' '}
                            {counter ? ` ${EmDash} ${counter.zoneName}` : ''}
                        </>
                    }
                    backTo={'..'}
                    padded
                >
                    {counter ? (
                        <CounterEditContent
                            counter={counter}
                            refetchCounterInfo={refetchCounterInfo}
                        />
                    ) : (
                        <Alert severity="error" sx={{ margin: '0 0 20px 0' }}>
                            <Localized
                                de="Zähler nicht gefunden."
                                fr="Compteur non trouvé."
                                it="Contatore non trovato."
                                en="Counter not found."
                            />
                        </Alert>
                    )}
                </ParkingPortalLayoutWithHeader>
            )}
        />
    );
}

function CounterEditContent({
    counter,
    refetchCounterInfo,
}: {
    counter: CloudConnectorCounterDetail;
    refetchCounterInfo: () => void;
}) {
    const navigate = useNavigate();
    const [localCount, setLocalCount] = useState(counter.count);

    const [updateAreaCounterState, updateAreaCounter] = useServerWrite<
        { zoneBarrierGateAreaCounterId: number; count: number },
        null
    >(context => ({
        url: `/ui-api/operator-account/counter/${context.zoneBarrierGateAreaCounterId}`,
    }));

    useServerSuccessEffect(updateAreaCounterState, () => {
        refetchCounterInfo();
        navigate('..');
    });

    return (
        <>
            <MobileSectionTitle>{counter.counterName}</MobileSectionTitle>
            {updateAreaCounterState.status === RequestStatus.ERROR && (
                <Alert severity="error" sx={{ margin: '0 0 20px 0' }}>
                    <Localized
                        de="Es ist ein Fehler aufgetreten."
                        fr="Une erreur est survenue."
                        it="Si è verificato un errore."
                        en="An error has occurred."
                    />
                </Alert>
            )}
            <UncontrolledMobileNumericInput
                label={
                    <Localized
                        de="Belegte Plätze"
                        fr="Places occupées"
                        it="Posti occupati"
                        en="Occupied spaces"
                    />
                }
                onChange={e => {
                    const count = parseInt(e.target.value, 10);
                    if (e.target.value === '') {
                        setLocalCount(0);
                    } else if (!isNaN(count)) {
                        setLocalCount(count);
                    }
                }}
                value={localCount.toString(10)}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="contained"
                type="submit"
                onClick={() => {
                    updateAreaCounter({
                        zoneBarrierGateAreaCounterId:
                            counter.zoneBarrierGateAreaCounterId,
                        count: localCount,
                    });
                }}
                loading={
                    updateAreaCounterState.status === RequestStatus.PENDING
                }
                sx={{
                    padding: '24px',
                    fontSize: '16px',
                    marginTop: '24px',
                }}
                disabled={
                    updateAreaCounterState.status === RequestStatus.PENDING ||
                    counter.count === localCount
                }
            >
                {updateAreaCounterState.status === RequestStatus.PENDING ? (
                    <PresetLoadingSpinner
                        color="inherit"
                        preset={LoadingSpinnerPresets.EmbedInButton}
                    />
                ) : (
                    <Localized
                        de="Speichern"
                        fr="Sauvegarder"
                        it="Salva"
                        en="Save"
                    />
                )}
            </Button>
        </>
    );
}
