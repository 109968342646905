import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { DateTime } from 'luxon';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { css } from '@emotion/css';
import { OperatorTypo } from '../ui/OperatorTypo.ts';
import { ColorHex } from '../ui/Colors.ts';
import { Language } from 'dg-web-shared/lib/Localized.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter.ts';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { Localized } from '../common/components/Localized.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export const enum DataPointUnit {
    PCS = 'PCS',
    CHF = 'CHF',
}

export function OperatorBarChart({
    data,
    dataKeys,
    tooltipNames,
    totalKey,
    yearMonthKey,
    unit = DataPointUnit.PCS,
    maxYValue,
}: {
    data: object[];
    dataKeys: string[];
    tooltipNames: string[];
    totalKey?: string;
    yearMonthKey: string;
    unit?: DataPointUnit;
    maxYValue?: number;
}): JSX.Element {
    const language = useOperatorLanguage();
    const theme = useTheme();
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 10, right: 35, bottom: 5 }}>
                <XAxis
                    dataKey={yearMonthKey}
                    minTickGap={0}
                    interval={0}
                    tickLine={false}
                    tick={<ChartMonthTick language={language} />}
                />
                <YAxis
                    orientation="right"
                    tickLine={false}
                    axisLine={false}
                    domain={
                        maxYValue
                            ? [0, roundToNearestTick(maxYValue)]
                            : undefined
                    }
                    tickCount={5}
                    tickFormatter={value => numberFormatter(value, unit, false)}
                    tick={{
                        ...Typo.captionC1,
                        fontWeight: 900,
                        fill: ColorHex.typo,
                        fillOpacity: 0.6,
                    }}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    isAnimationActive={false}
                    content={p => (
                        <CustomTooltipContent
                            dataKeys={dataKeys}
                            totalKey={totalKey}
                            tooltipNames={tooltipNames}
                            active={p.active}
                            payload={p.payload}
                            unit={unit}
                        />
                    )}
                    cursor={{ fill: theme.palette.blue.light }}
                />
                <CartesianGrid vertical={false} stroke="#ccc" />
                {dataKeys.map(key => (
                    <Bar
                        key={key}
                        dataKey={key}
                        stackId="1"
                        strokeWidth={0}
                        fill={colorMap[key]}
                        barSize={24}
                        isAnimationActive={false}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

function CustomTooltipContent({
    dataKeys,
    totalKey,
    tooltipNames,
    payload,
    unit,
}: {
    dataKeys: string[];
    totalKey?: string;
    tooltipNames: string[];
    active: boolean | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
    unit: DataPointUnit;
}) {
    const total: number | null = totalKey && payload[0]?.payload[totalKey];

    if (totalKey && !total) {
        return null;
    }

    return (
        <Box
            sx={{
                padding: 1,
                backgroundColor: 'rgb(50, 73, 107, 0.8)',
                color: theme => theme.palette.white.main,
            }}
        >
            {total && (
                <TooltipItem
                    variant={'subtitle2'}
                    name={
                        <Localized
                            de="Gesamt"
                            fr="Totale"
                            it="Totale"
                            en="Total"
                        />
                    }
                    value={total}
                    unit={unit}
                />
            )}
            {dataKeys
                .map((key, index) => {
                    const value = payload[0]?.payload[key];
                    if (value) {
                        return (
                            <TooltipItem
                                variant={'body2'}
                                key={key}
                                name={tooltipNames[index]}
                                value={value}
                                unit={unit}
                            />
                        );
                    }
                    return null;
                })
                .reverse()}
        </Box>
    );
}

function TooltipItem({
    variant,
    name,
    value,
    unit,
}: {
    variant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >;
    name: React.ReactNode;
    value: number;
    unit: DataPointUnit;
}) {
    return (
        <Typography
            variant={variant}
            sx={{
                display: 'flex',
                marginBottom: variant === 'subtitle2' ? 1 : 0,
            }}
        >
            <Box sx={{ flexGrow: 1, paddingRight: 1 }}>{name}</Box>
            <div>
                {numberFormatter(value, unit, unit === DataPointUnit.CHF)}
            </div>
        </Typography>
    );
}

function ChartMonthTick({
    language,
    x,
    y,
    payload,
}: {
    language: Language;
    x?: number;
    y?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}): JSX.Element {
    const tickValue: string = payload.value;
    const tickMonth = DateTime.fromISO(tickValue);
    const currentMonth = DateTime.now();
    const isCurrent =
        tickMonth.month === currentMonth.month &&
        tickMonth.year === currentMonth.year;
    const isInFuture = tickMonth > currentMonth;
    return (
        <g
            className={css({
                alignmentBaseline: 'middle',
                ...OperatorTypo.subcaption,
                fill: ColorHex.typo,
                fillOpacity: 0.6,
                textAnchor: 'middle',
            })}
        >
            <rect
                className={css({
                    fill: isCurrent ? ColorHex.lightblue : 'transparent',
                    fillOpacity: 1,
                })}
                x={x ? x - 14 : -14}
                y={y ? y + 3 : 3}
                width={28}
                height={16}
            />
            <text
                className={css({
                    ...OperatorTypo.subcaption,
                    fill: isCurrent ? ColorHex.white : ColorHex.typo,
                    fillOpacity: getTextOpacity(isInFuture, isCurrent),
                    alignmentBaseline: 'middle',
                })}
                x={x}
                y={y}
                dy={12}
            >
                {Formatter.monthNameAbbr(tickMonth, language).toUpperCase()}
            </text>
        </g>
    );
}

function getTextOpacity(isDisabled: boolean, isCurrent: boolean) {
    if (isDisabled) {
        return 0.3;
    }
    if (isCurrent) {
        return 1;
    }
    return 0.6;
}

const colorMap: { [key: string]: string } = {
    revenueTwint: 'rgb(54,54,54)',
    countTwint: 'rgb(54,54,54)',
    revenueParkingpay: 'rgba(0,94,145,0.9)',
    countParkingpay: 'rgba(0,94,145,0.9)',
    revenueEasypark: 'rgb(250,94,168)',
    countEasypark: 'rgb(250,94,168)',
    revenueCoop: 'rgb(255,180,94)',
    countCoop: 'rgb(255,180,94)',
    revenueSwisspass: 'rgba(255,89,89)',
    countSwisspass: 'rgba(255,89,89)',
    revenuePnR: 'rgb(147,53,66)',
    countPnR: 'rgb(147,53,66)',
    revenueParkingabo: 'rgb(0,185,215)',
    countParkingabo: 'rgb(0,185,215)',
    revenueParkingaboCounter: 'rgb(0,127,195)',
    countParkingaboCounter: 'rgb(0,127,195)',
    revenueQuickcheckout: 'rgb(68,203,75)',
    countQuickcheckout: 'rgb(68,203,75)',
    countTom: 'rgb(50,72,105)',
    revenueTom: 'rgb(50,72,105)',
    countTcs: 'rgb(255, 235, 2)',
    revenueTcs: 'rgb(255, 235, 2)',
};

export function numberFormatter(
    value: string | number,
    unit: DataPointUnit,
    showDecimals: boolean,
) {
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
    const formattedNumber = NumberFormatter.format(
        'de',
        parsedValue,
        showDecimals ? '#,###.##' : '#,###',
    );

    switch (unit) {
        case DataPointUnit.PCS:
            return formattedNumber;
        case DataPointUnit.CHF:
            return NumberFormatter.currencyCentsToLocalPrice(
                'de',
                parsedValue,
                true,
                showDecimals,
            );
    }
}

function roundToNearestTick(value: number): number {
    const intervalSize = 10 ** Math.floor(Math.log10(value));
    return Math.ceil(Math.ceil(value) / intervalSize) * intervalSize;
}
