import {
    extractActiveFiltersFromQueryParams,
    FilterConfig,
    FilterFieldOptions,
    OMNIBOX_SEARCH_PARAM_NAME,
    useQuery,
} from './OperatorFilterHelpers.tsx';
import { useEffect, useState } from 'react';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { CustomerSearchResult } from '../../parkingabo-users/OperatorParkingaboUsers.tsx';
import { ProductSearchResult } from '../../products/OperatorProducts.tsx';
import {
    BackendFilterConfig,
    BackendFilterType,
    BackendFilterValueConfig,
} from './OperatorFilterBackendConfiguration.tsx';
import { CheckinStatisticResponse } from '../../statistics/OperatorStatisticsRoute.tsx';
import { OffstreetTransactionsSearchResult } from '../../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { PeripheryHistorySearchResult } from '../../periphery-history/PeripheryHistory.tsx';
import { PARKINGABO_BADGE_URL } from '../../parkingabo-users/OperatorParkingaboUsersVehicleFormBadgeDetails.tsx';
import { PARKINGABO_BADGE_LENGTH } from 'dg-web-shared/lib/RfidCardValidation.ts';
import { Base64 } from 'dg-web-shared/common/utils/Base64.ts';

export function useProductFilterConfiguration() {
    return useFilterConfiguration<ProductSearchResult>(
        '/ui-api/operator-account/product/filter-configuration',
        `/ui-api/operator-account/product/search`,
    );
}

export function useOffstreetTransactionsFilterConfiguration() {
    return useFilterConfiguration<OffstreetTransactionsSearchResult>(
        '/ui-api/operator-account/offstreet-transactions/filter-configuration',
        `/ui-api/operator-account/offstreet-transactions/search`,
        removeQRScanPrefix,
    );
}

export function useParkingaboUserFilterConfiguration() {
    return useFilterConfiguration<CustomerSearchResult>(
        '/ui-api/operator-account/parkingabo/customer/filter-configuration',
        `/ui-api/operator-account/parkingabo/customer/search`,
        removeQRScanPrefix,
    );
}

export function useCloudConnectorPeripheryHistoryFilterConfiguration() {
    return useFilterConfiguration<PeripheryHistorySearchResult>(
        '/ui-api/operator-account/periphery-history/filter-configuration',
        `/ui-api/operator-account/periphery-history/search`,
        removeQRScanPrefix,
    );
}

export type FilteredSerchResult =
    | ProductSearchResult
    | CustomerSearchResult
    | PeripheryHistorySearchResult
    | OffstreetTransactionsSearchResult;

export function useStatisticsFilterConfiguration() {
    return useFilterConfiguration<CheckinStatisticResponse>(
        '/ui-api/operator-account/statistics/filter-configuration',
        `/ui-api/operator-account/statistics`,
    );
}

function useFilterConfiguration<T>(
    filterConfigUrl: string,
    searchUrl: string,
    adjustQuery?: (query: URLSearchParams) => URLSearchParams,
) {
    const query = useQuery();
    const searchText = query.get(OMNIBOX_SEARCH_PARAM_NAME);

    const [filterConfig, setFilterCofig] = useState<FilterConfig>({});
    const [filterConfigState] = useServerFetch<BackendFilterConfig[], object>(
        () => ({
            url: filterConfigUrl,
        }),
        {},
    );

    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 450);

        return () => clearTimeout(timeoutId);
    }, [searchText]);

    const [debouncedQuery, setDebouncedQuery] = useState(query);
    useEffect(() => {
        if (searchText == debouncedSearchText) {
            const adjustedQuery = adjustQuery ? adjustQuery(query) : query;
            const timeoutId = setTimeout(() => {
                setDebouncedQuery(adjustedQuery);
            }, 50);

            return () => clearTimeout(timeoutId);
        }
    }, [query, debouncedSearchText]);

    useEffect(() => {
        if (filterConfigState.status !== RequestStatus.SUCCESS) {
            setFilterCofig({});
            return;
        }
        setFilterCofig(buildFilterConfig(filterConfigState.data));
    }, [filterConfigState.data]);

    const activeFilters = extractActiveFiltersFromQueryParams(
        query,
        filterConfig,
    );

    const [listState, refetchList] = useServerFetch<
        T,
        { queryString: string },
        null
    >(
        context => ({
            url: searchUrl + context.queryString,
        }),
        {
            queryString: '?' + debouncedQuery,
        },
    );
    return {
        filterConfig: filterConfig,
        activeFilters: activeFilters,
        listState: listState,
        refetchList: refetchList,
        filterConfigState: filterConfigState,
        searchText: searchText,
    };
}

const PARKINGPAY_TICKET_URL = 'https://parkingpay.ch/t/';
const TICKET_BASE_256_LENGTH = 12;

function removeQRScanPrefix(query: URLSearchParams): URLSearchParams {
    const searchText = query.get(OMNIBOX_SEARCH_PARAM_NAME);
    if (
        searchText &&
        searchText.startsWith(PARKINGABO_BADGE_URL) &&
        searchText.length ===
            PARKINGABO_BADGE_LENGTH + PARKINGABO_BADGE_URL.length
    ) {
        query.set(
            OMNIBOX_SEARCH_PARAM_NAME,
            searchText.replace(PARKINGABO_BADGE_URL, ''),
        );
    }
    if (
        searchText &&
        searchText.startsWith(PARKINGPAY_TICKET_URL) &&
        searchText.length ===
            PARKINGPAY_TICKET_URL.length + TICKET_BASE_256_LENGTH
    ) {
        const ticketString = searchText.replace(PARKINGPAY_TICKET_URL, '');
        if (/^[A-Za-z0-9+/=_-]*$/.test(ticketString)) {
            const ticketIdentification = parseTicketBase64(ticketString);
            query.set(OMNIBOX_SEARCH_PARAM_NAME, ticketIdentification);
        }
    }
    return query;
}

function parseTicketBase64(value: string) {
    try {
        const binaryString = Base64.decodeURLSafe(value);
        const facility =
            binaryString.charCodeAt(0) * 256 + binaryString.charCodeAt(1);
        const facilityString = facility.toString().padStart(4, '0');
        const type =
            binaryString.charCodeAt(2) * 256 + binaryString.charCodeAt(3);
        const typeString = type.toString().padStart(2, '0');
        const ticket =
            binaryString.charCodeAt(4) * 256 + binaryString.charCodeAt(5);
        const ticketString = ticket.toString().padStart(4, '0');
        const role =
            binaryString.charCodeAt(6) * 256 + binaryString.charCodeAt(7);
        const roleString = role.toString().padStart(4, '0');
        return `${facilityString}.${ticketString}.${roleString}.${typeString}`;
    } catch (e) {
        console.error('Failed to parse ticket string:', value, e);
        return value;
    }
}

function buildFilterConfig(filterConfigData: BackendFilterConfig[]) {
    const filters: FilterConfig = {};
    filterConfigData.forEach(filter => {
        switch (filter.type) {
            case BackendFilterType.DATE:
                filters[filter.key] = {
                    type: BackendFilterType.DATE,
                    displayName: filter.displayName,
                    disableSelectionInFuture: filter.disableSelectionInFuture,
                    value: null,
                };
                break;
            case BackendFilterType.SELECTION:
                filters[filter.key] = {
                    type: BackendFilterType.SELECTION,
                    displayName: filter.displayName,
                    values: filter.filters.reduce(
                        (
                            map: FilterFieldOptions,
                            filter: BackendFilterValueConfig,
                        ) => {
                            map[filter.value] = {
                                displayName: filter.displayName,
                            };
                            return map;
                        },
                        {},
                    ),
                };
                break;
            case BackendFilterType.DATE_RANGE:
                filters[filter.key] = {
                    type: BackendFilterType.DATE_RANGE,
                    displayName: filter.displayName,
                    disableSelectionInFuture: filter.disableSelectionInFuture,
                    fromValue: null,
                    toValue: null,
                };
                break;
            case BackendFilterType.ZONE:
                filters[filter.key] = {
                    type: BackendFilterType.ZONE,
                    displayName: filter.displayName,
                    filters: filter.filters.map(filter => ({
                        displayName: filter.displayName,
                        values: filter.zones.reduce(
                            (
                                map: FilterFieldOptions,
                                filter: BackendFilterValueConfig,
                            ) => {
                                map[filter.value] = {
                                    displayName: filter.displayName,
                                };
                                return map;
                            },
                            {},
                        ),
                    })),
                };
                break;
        }
    });
    return filters;
}
